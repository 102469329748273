import { Icon } from "Components/index";
import React from "react";

function Toggle({ value, handleClick, name }) {
  return (
    <>
      <span className='toggle' data-checked={value} onClick={handleClick}>
        <span className='toggle-pip'>
          <Icon icon='Check' />
        </span>
      </span>
      <input name={name} type='checkbox' checked={value || false} onChange={handleClick} readOnly />
    </>
  );
}

export default Toggle;
