import React from "react";

function AlignArrowRight() {
  return (
    <>
      
        <path
          d='M4.75 4.75V19.25'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          d='M8.75 12H19.25'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          d='M15.75 8.75L19.25 12L15.75 15.25'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
      
    </>
  );
}

export default AlignArrowRight;
