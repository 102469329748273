import { DateTime, TableCell } from "Components/index";

function BillingInvoicesRow({ type, row }) {
  switch (type) {
    case "header":
      return (
        <tr>
          <TableCell type='header' contents='Invoice #' />
          <TableCell type='header' contents='date' />
          <TableCell type='header' contents='Users / AI' />
          <TableCell type='header' contents='Amount' />
          <TableCell type='header' contents='' />
        </tr>
      );
    default:
      return (
        <tr>
          <TableCell type='text' contents={row.invoice_number} />
          <TableCell type='text' contents={<DateTime type='short' dateTime={row.created_at} />} />
          <TableCell type='text' contents={row.agent_count} />
          <TableCell type='text' contents={row.amount_with_tax.toLocaleString()} />
          <TableCell
            type='text'
            contents={
              <a
                href={
                  "https://repzijxdaksdftljogfb.supabase.co/storage/v1/object/public/public/invoice.pdf"
                }
                target='_blank'
                rel='noreferrer'
              >
                download
              </a>
            }
          />
        </tr>
      );
  }
}

export default BillingInvoicesRow;
