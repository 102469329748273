export const NavItemsPrimary = [
  {
    id: 1,
    label: "Tickets",
    icon: "Tickets",
    link: "/tickets",
  },
  {
    id: 2,
    label: "Dashboard",
    icon: "Dashboard",
    link: "/dashboard",
  },
  {
    id: 3,
    label: "Queue",
    icon: "Queue",
    link: "/queue",
  },
  {
    id: 4,
    label: "History",
    icon: "History",
    link: "/history",
  },
  // {
  //   id: 6,
  //   label: "Reports",
  //   icon: "Reports",
  //   link: "/reports",
  // },
  {
    id: 7,
    label: "Saved replies",
    icon: "Replies",
    link: "/replies",
  },
  // {
  //   id: 8,
  //   label: "Customers",
  //   icon: "Customers",
  //   link: "/customers",
  // },
  {
    id: 9,
    label: "Articles",
    icon: "Articles",
    link: "/articles",
  },
];

export const NavItemsSecondary = [
  {
    id: 1,
    label: "Support",
    icon: "Support",
    link: "/support",
  },
  {
    id: 2,
    label: "Settings",
    icon: "Settings",
    link: "/settings",
  },
];

export const NavItemsSettings = [
  {
    id: 1,
    label: "Your account",
    link: "/settings/your-account",
  },
  {
    id: 2,
    label: "Company",
    link: "/settings/company",
  },
  {
    id: 3,
    label: "Billing",
    link: "/settings/billing",
  },
  {
    id: 4,
    label: "Team",
    link: "/settings/team",
  },
  {
    id: 6,
    label: "Tags",
    link: "/settings/tags",
  },
  {
    id: 7,
    label: "Policies",
    link: "/settings/policies",
  },
  // {
  //   id: 8,
  //   label: "Ticket settings",
  //   link: "/settings/ticket",
  // },
  {
    id: 9,
    label: "Notifications",
    link: "/settings/notifications",
  },
  // {
  //   id: 10,
  //   label: "Customer data",
  //   link: "/settings/customer-data",
  // },
  {
    id: 11,
    label: "Integrations",
    link: "/settings/integrations",
  },
];
