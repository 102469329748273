import { FormBlock, FormItem, FormRow, Tag } from "Components/index";
import { SettingsFooter } from "Compositions/index";
import { useGetSlugRow, useForm } from "Hooks/";
import PanelHeader from "Layouts/Panel/PanelHeader";
import { SettingsSection } from "Layouts/index";
import React from "react";
import { useParams } from "react-router-dom";

function SavedReplyItem() {
  const slug = useParams().slug;
  const {
    data: slugTag,
    isLoading: slugTagIsLoading,
    error: slugTagError,
  } = useGetSlugRow("tags", slug);

  const id = slugTag?.id;
  const table = "tags";

  const {
    formState,
    unsavedChanges,
    statusText,
    isLoading,
    error,
    discardChanges,
    handleFormTextChange,
    saveChanges,
  } = useForm(table, id, {});

  const pageDefinitions = {
    title: "Your account",
    icon: "YourAccount",
    subtitle: "Tell us about yourself",
  };

  if (slugTagIsLoading || slugTagError || isLoading || error) {
    return <p>loading...</p>;
  }
  return (
    <>
      <PanelHeader
        title={
          <>
            <h1>Tag:</h1> <Tag id={formState.id} />
          </>
        }
      />
      <SettingsSection>
        <FormBlock>
          <FormRow>
            <FormItem
              label='Category'
              name='tag_category'
              itemType='input'
              inputType='text'
              value={formState.tag_category}
              onChange={handleFormTextChange}
            />
            <FormItem
              label='Shortcut Key'
              name='shortcut_key'
              itemType='input'
              inputType='text'
              value={formState.shortcut_key}
              onChange={handleFormTextChange}
            />
            <FormItem
              label='Name'
              name='name'
              itemType='input'
              inputType='text'
              value={formState.name}
              onChange={handleFormTextChange}
            />
          </FormRow>
        </FormBlock>
      </SettingsSection>
      <SettingsSection
        settingsType='tag-automations'
        title='Automations'
        subtitle='There will be stuff here later'
      ></SettingsSection>
      <SettingsFooter
        unsavedChanges={unsavedChanges}
        discardChanges={discardChanges}
        saveChanges={saveChanges}
        pageName={pageDefinitions.title}
        unsavedChangesText={statusText}
      />
    </>
  );
}

export default SavedReplyItem;
