import React from "react";
import Moment from "react-moment";

function DateTime({ dateTime, type }) {
  let formattedDateTime;

  switch (type) {
    case "relative-short":
      formattedDateTime = (
        <Moment format='hh:mma' titleFormat='D MMM YYYY' withTitle>
          {dateTime}
        </Moment>
      );
      break;
    case "short":
      formattedDateTime = (
        <Moment format='YYYY/MM/DD' withTitle>
          {dateTime}
        </Moment>
      );
      break;
    case "long":
      formattedDateTime = (
        <Moment format='D MMMM YYYY' withTitle>
          {dateTime}
        </Moment>
      );
      break;
    default:
      formattedDateTime = (
        <Moment fromNow titleFormat='D MMM YYYY' withTitle>
          {dateTime}
        </Moment>
      );
  }

  return <>{formattedDateTime}</>;
}

export default DateTime;
