import React from "react";
import { Button } from "Components/index";

function CreditCard({ id, lastFour, expiry, name, preferred, type, cardholder, setPreferred }) {
  return (
    <div className='credit-card' data-is-preferred={preferred}>
      <header>
        <img
          className='credit-card-logo'
          src={`/images/credit-card-logos/${type}-light.svg`}
          alt={type}
        />

        <Button iconOnly='Trash' size='small' use='text' />
        <h3>{name}</h3>
      </header>
      <div className='card-details'>
        <div className='credit-card-number'>**** **** **** {lastFour}</div>
        <div className='credit-card-expiry'>{expiry}</div>
      </div>
      <div className='card-details'>
        <div className='credit-card-name'>{cardholder}</div>
        <div className='credit-card-preferred'>
          {preferred ? (
            <span className='is-preferred'>Preferred</span>
          ) : (
            <Button
              use='text'
              label='Make preferred'
              name='billing_default_card'
              onClick={setPreferred}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CreditCard;
