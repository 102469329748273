import PageHeader from "Compositions/PageHeader/PageHeader";
import { useGetRows } from "Hooks/";
import TeamRow from "./TeamRow";
import InviteTeamMember from "./InviteTeamMember/InviteTeamMember";
import { createPortal } from "react-dom";
import { Outlet } from "react-router-dom";

function Team() {
  const { data, isLoading, error } = useGetRows({ table: "user_profiles" });

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className='settings-page'>
      <PageHeader title='Team' icon='Team' guideUrl='https://fixatehq.com' subtitle='subtitle' />
      <InviteTeamMember />
      <table>
        <thead>
          <TeamRow type='header' />
        </thead>
        <tbody>
          {data.map((row) => (
            <TeamRow key={row.id} row={row} />
          ))}
        </tbody>
      </table>
      {createPortal(<Outlet />, document.body)}
    </div>
  );
}

export default Team;
