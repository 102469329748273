import React from "react";

function EllipsisVertical() {
  return (
    <>
      <path
        fill='currentColor'
        d='M13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z'
      ></path>
      <path
        fill='currentColor'
        d='M13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z'
      ></path>
      <path
        fill='currentColor'
        d='M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z'
      ></path>
    </>
  );
}

export default EllipsisVertical;
