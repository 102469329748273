import { Avatar, Icon } from "Components/index";

// ToDo:
// - [ ] Is a user currently looking at the ticket?
// - [ ] Is the user writing a message?
// - [ ] What are the current skills available?
// - [ ] What are the skills & level required for the ticket?
// - [ ] Is the ticket stranded?

function CellTicketLiveActivty({ contents }) {
  return (
    <>
      <span className='message-count'>
        <Icon icon='Messages' size='small' />
        {contents.message_count}
      </span>
      <span className='live-activity'>
        <Avatar
          url='https://repzijxdaksdftljogfb.supabase.co/storage/v1/object/public/public/avatars/15133679-172d-403c-ba59-1a97cdc80005-1692118121661'
          profileColor={81}
          size='xSmall'
        />
        Replying...
      </span>
      <span className='is-not-stranded'>
        <Icon icon='CircleCheck' />
      </span>
      {/* <span className='is-stranded'>
        <Icon icon='CircleCross' />
      </span> */}
    </>
  );
}

export default CellTicketLiveActivty;
