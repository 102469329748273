import MultiPicker from "Components/MultiPicker/MultiPicker";
import { FormBlock, FormItem, FormRow } from "Components/index";
import { SettingsFooter } from "Compositions/index";
import { useGetSlugRow, useForm } from "Hooks/";
import PanelHeader from "Layouts/Panel/PanelHeader";
import { SettingsSection } from "Layouts/index";
import React from "react";
import { useParams } from "react-router-dom";

function SavedReplyItem() {
  const slug = useParams().slug;
  const {
    data: slugReply,
    isLoading: slugReplyIsLoading,
    error: slugReplyError,
  } = useGetSlugRow("saved_replies", slug);

  const id = slugReply?.id;
  const table = "saved_replies";

  const {
    formState,
    unsavedChanges,
    statusText,
    isLoading,
    error,
    discardChanges,
    handleFormTextChange,
    saveChanges,
  } = useForm(table, id, {});

  const pageDefinitions = {
    title: "Your account",
    icon: "YourAccount",
    subtitle: "Tell us about yourself",
  };

  if (slugReplyIsLoading || slugReplyError || isLoading || error) {
    return <p>loading...</p>;
  }
  return (
    <>
      <PanelHeader
        title={
          <>
            <h1>{formState.title}</h1>
          </>
        }
      />

      <SettingsSection>
        <FormBlock>
          <MultiPicker
            value={formState.shortcut_key}
            onChange={handleFormTextChange}
            name='shortcut_key'
            table='saved_replies'
          />
          <FormRow>
            <FormItem
              label='Shortcut Key'
              name='shortcut_key'
              itemType='input'
              inputType='text'
              value={formState.shortcut_key}
              onChange={handleFormTextChange}
            />
            <FormItem
              label='Title'
              name='title'
              itemType='input'
              inputType='text'
              value={formState.title}
              onChange={handleFormTextChange}
            />
          </FormRow>
          <FormRow>
            <FormItem
              label='Content'
              name='content'
              itemType='input'
              inputType='textarea'
              value={formState.content}
              onChange={handleFormTextChange}
            />
          </FormRow>
        </FormBlock>
      </SettingsSection>
      <SettingsFooter
        unsavedChanges={unsavedChanges}
        discardChanges={discardChanges}
        saveChanges={saveChanges}
        pageName={pageDefinitions.title}
        unsavedChangesText={statusText}
      />
    </>
  );
}

export default SavedReplyItem;
