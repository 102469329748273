import { DateTime } from "Components/index";
import React from "react";

function TicketMessage({ message }) {
  return (
    <section className='ticket-message'>
      <DateTime dateTime={message.created_at} />
      <div className='message-body' dangerouslySetInnerHTML={{ __html: message.message }} />
    </section>
  );
}

export default TicketMessage;
