import { useForm } from "Hooks/";
import { FormItem, FormRow, FormBlock } from "Components/";
import { PageHeader } from "Compositions/";
import { SettingsSection } from "Layouts/";
import SettingsFooter from "Compositions/SettingsFooter/SettingsFooter";
import { useCurrentUser } from "Hooks/";
import { timezoneOptions } from "Data/timezones";
import { dayTimeOptions } from "Data/dayTimes";

import FileUpload from "Components/FileUpload/FileUpload";

function YourAccount() {
  const { id } = useCurrentUser();
  const table = "user_profiles";
  const timezones = timezoneOptions;
  const dayTimes = dayTimeOptions;
  const {
    formState,
    unsavedChanges,
    statusText,
    isLoading,
    error,
    discardChanges,
    handleFormTextChange,
    handleFormToggleChange,
    handleFormSelectChange,
    saveChanges,
    handleFileUpload,
    clearImage,
  } = useForm(table, id, {});

  const pageDefinitions = {
    title: "Your account",
    icon: "YourAccount",
    subtitle: "Tell us about yourself",
  };

  if (isLoading || formState.first_name === undefined) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className='settings-page'>
      <PageHeader
        title={pageDefinitions.title}
        icon={pageDefinitions.icon}
        guideUrl='https://fixatehq.com'
        subtitle={pageDefinitions.subtitle}
      />
      <SettingsSection>
        <FormBlock>
          <FormRow>
            <FileUpload
              label='Profile picture'
              imageUrl={formState.avatar}
              altText={formState.first_name + " " + formState.last_name}
              name='avatar'
              initials={formState.initials}
              imageSize='xxLarge'
              imageStyle='circle'
              profileColor={formState.profile_color}
              handleFileUpload={handleFileUpload}
              clearFile={clearImage}
              helperSecondary='JPG, PNG or GIF - Max file size: 5MB - ideal size: 512px by 512px'
            />
          </FormRow>

          <FormRow>
            <FormItem
              label='First name'
              name='first_name'
              itemType='input'
              inputType='text'
              value={formState.first_name}
              onChange={handleFormTextChange}
            />
            <FormItem
              label='Last name'
              name='last_name'
              itemType='input'
              inputType='text'
              value={formState.last_name}
              onChange={handleFormTextChange}
            />
          </FormRow>
          <FormRow>
            <FormItem
              label='Email'
              name='email'
              itemType='input'
              inputType='email'
              value={formState.email}
              onChange={handleFormTextChange}
            />
          </FormRow>
          <FormRow>
            <FormItem
              label='Email signature'
              name='signature'
              itemType='input'
              inputType='textarea'
              helperPrimary='This will be appended to the bottom of all emails you send.'
              value={formState.signature}
              onChange={handleFormTextChange}
            />
          </FormRow>
          <FormRow>
            <FormItem
              label='Custom working hours'
              name='custom_timezone_enabled'
              itemType='toggle'
              toggleType='switch'
              helperPrimary={
                "By default, we'll use your companies HQ timezone and default working hours. This is used to predict queue completion time."
              }
              helperSecondary='Default: Chicago time (currently 3:52pm), 9am - 5:30pm'
              value={formState.custom_timezone_enabled}
              onChange={handleFormToggleChange}
            >
              {formState.custom_timezone_enabled && (
                <>
                  <FormRow>
                    <FormItem
                      label='Working Timezone'
                      name='custom_timezone'
                      itemType='input'
                      inputType='select'
                      placeholder='Select a timezone'
                      options={timezones}
                      value={timezones.find((option) => option.value === formState.custom_timezone)} // Update this line
                      onChange={handleFormSelectChange}
                    ></FormItem>
                  </FormRow>
                  <FormRow>
                    <FormItem
                      label='Day start'
                      name='day_start'
                      itemType='input'
                      inputType='select'
                      placeholder='09:00AM (default)'
                      options={dayTimes}
                      value={dayTimes.find((option) => option.value === formState.day_start)} // Update this line
                      onChange={handleFormSelectChange}
                    ></FormItem>
                    <FormItem
                      label='Day end'
                      name='day_end'
                      itemType='input'
                      inputType='select'
                      placeholder='05:30pm (default)'
                      options={dayTimes}
                      value={dayTimes.find((option) => option.value === formState.day_end)} // Update this line
                      onChange={handleFormSelectChange}
                    ></FormItem>
                  </FormRow>
                </>
              )}
            </FormItem>
          </FormRow>
        </FormBlock>
      </SettingsSection>
      <SettingsFooter
        unsavedChanges={unsavedChanges}
        discardChanges={discardChanges}
        saveChanges={saveChanges}
        pageName={pageDefinitions.title}
        unsavedChangesText={statusText}
      />
    </div>
  );
}

export default YourAccount;
