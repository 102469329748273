import { useQuery } from "@tanstack/react-query";
import { supabase } from "Config/supabase";
import { useCurrentUser } from "Hooks/";

export function useGetRows({
  table,
  id,
  sortColumn,
  sortAscending = false,
  successCallback,
  filter,
}) {
  const { organization } = useCurrentUser();
  const orgId = organization;

  return useQuery({
    staleTime: 10 * (60 * 1000), // 10 minutes
    enabled: !!orgId,
    queryKey: [table],
    queryFn: async () => {
      let query = supabase.from(table).select("*");

      if (table !== "organizations" && table !== "tag_categories") {
        query = query.eq("organization", orgId);
      }
      if (sortColumn) {
        query = query.order(sortColumn, { ascending: sortAscending });
      }
      if (filter) {
        query = query.filter((row) => filter(row));
      }

      const { data, error } = await query; // Just await the query

      if (error) throw error;
      if (successCallback) successCallback(data);
      return data;
    },
  });
}
