import { FormItem, FormRow } from "Components/";

function NotificationItem({
  label,
  enabledName,
  description,
  enabled,
  enabledOnChange,
  notificationValue,
  notificationOnChange,
  notificationOptions,
  notificationName,
}) {
  return (
    <FormRow>
      <FormItem
        label={label}
        name={enabledName}
        itemType='toggle'
        toggleType='checkbox'
        helperPrimary={description}
        value={enabled}
        onChange={enabledOnChange}
      />

      <FormItem
        name={notificationName}
        itemType='input'
        inputType='select'
        placeholder='choose...'
        showClear={false}
        disabled={!enabled}
        options={notificationOptions}
        value={notificationValue}
        onChange={notificationOnChange}
      ></FormItem>
    </FormRow>
  );
}

export default NotificationItem;
