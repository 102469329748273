import { Icon } from "Components/";
import Tooltip from "Components/Tooltip/Tooltip";
import { Tag } from "Components/index";
import React from "react";

function QueueStatus() {
  const stranded_count = 2;
  const users_online = 16;
  const isStranded = stranded_count > 0;

  return (
    <div
      className={`queue-status queue-status-sidebar ${isStranded ? "stranded" : ""}`}
      data-is-stranded={isStranded}
    >
      <header>
        <h4>Queue Status</h4>
        <span className='queue-status-users-online'>
          <span data-tooltip-id='queue-status-user-count' className='count'>
            <Icon size='small' icon='Users' /> {users_online}
          </span>
          <Tooltip label={`${users_online} agents online`} attachTo='queue-status-user-count' />
        </span>
      </header>
      <ul>
        <li className={`queue-status-item queue-status-stranded ${isStranded ? "stranded" : ""}`}>
          <span className='title'>Stranded</span>
          <span data-tooltip-id='queue-status-stranded-count' className='count'>
            <Icon size='small' icon='CircleQuestion' /> {stranded_count}
          </span>
          <Tooltip attachTo='queue-status-stranded-count'>
            <h4>Required Skills</h4>
            <ul className='tag-list'>
              <li>
                <Tag id='a4d26d36-2a89-420c-a893-f8c1d9070fc2' />
              </li>
              <li>
                <Tag id='a4d26d36-2a89-420c-a893-f8c1d9070fc2' />
              </li>
            </ul>
          </Tooltip>
        </li>
        <li className='queue-status-item queue-status-oldest'>
          <span className='title'>Oldest</span>
          <span className='count'>14hr</span>
        </li>
        <li className='queue-status-item queue-status-empty-time'>
          <span className='title'>Time to complete</span>
          <span className='count'>1hr 53m</span>
        </li>
      </ul>
    </div>
  );
} 

export default QueueStatus;
