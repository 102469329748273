import { Icon } from "Components/index";

import Select from "Components/Forms/Items/Input/Select";

function FormItem({
  inputType = "text",
  name,
  value,
  onChange,
  iconLead,
  iconTrail,
  options,
  placeholder,
  autocomplete,
  readOnly,
  disabled,
  showClear,
}) {
  switch (inputType) {
    case "textarea":
      return (
        <div className='input-container' data-input-type={inputType}>
          <textarea name={name} value={value} onChange={onChange} placeholder={placeholder}>
            {value}
          </textarea>
        </div>
      );
    case "select":
      return (
        <div className='input-container' data-input-type={inputType}>
          <Select
            options={options}
            value={value}
            name={name}
            isDisabled={disabled}
            showClear={showClear}
            onChange={onChange}
            placeholder={placeholder}
          />
        </div>
      );
    default:
      return (
        <div className='input-container' data-input-type={inputType}>
          {iconLead && <Icon icon={iconLead} position='element-lead' />}
          <input
            type={inputType}
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            autoComplete={autocomplete}
            readOnly={readOnly}
          />
          {iconTrail && <Icon icon={iconTrail} position='element-trail' />}
        </div>
      );
  }
}

export default FormItem;
