import PageHeader from "Compositions/PageHeader/PageHeader";
import { useCurrentUser } from "Hooks/";

function Dashboard() {
  const { id, first_name, last_name, avatar_url, initials, organization, isLoading, error } =
    useCurrentUser(); // Use the hook to get the user data

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <PageHeader title='Dashboard' icon='Dashboard' subtitle='subtitle' />
      hello
      <p>First Name: {first_name}</p>
      <p>Last Name: {last_name}</p>
      <p>Avatar URL: {avatar_url}</p>
      <p>Initials: {initials}</p>
      <p>id: {id}</p>
      <p>Organization: {organization}</p>
    </div>
  );
}

export default Dashboard;
