import CreditCard from "Components/CreditCard/CreditCard";
import { useGetRows } from "Hooks/useGetRows";
import { SettingsSection } from "Layouts/index";
import React, { useState } from "react";

function BillingPaymentMethod({ defaultCard, name, onChange }) {
  const { data: paymentMethods, isLoading, error } = useGetRows({ table: "credit_cards" });
  const [currentDefaultCard, setCurrentDefaultCard] = useState(defaultCard);

  const handleDefaultCardClick = (cardId) => {
    setCurrentDefaultCard(cardId);
    onChange({ target: { name, value: cardId } });
  };
  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <SettingsSection title='Payment methods'>
      <div className='credit-cards'>
        {paymentMethods.map((method, index) => (
          <CreditCard
            key={index}
            id={method.id}
            type={method.card_type}
            name={method.nickname}
            cardholder={method.cardholder_name}
            lastFour={method.last_four}
            expiry={method.expiry}
            preferred={method.id === currentDefaultCard}
            setPreferred={() => handleDefaultCardClick(method.id)}
          />
        ))}
      </div>
    </SettingsSection>
  );
}

export default BillingPaymentMethod;
