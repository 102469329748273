import { Button, KeyBoardShortcut } from "Components/";

function SettingsFooter({
  pageName,
  SaveTextOverride,
  discardChanges,
  saveChanges,
  unsavedChanges,
  unsavedChangesText,
}) {
  const saveText = SaveTextOverride
    ? SaveTextOverride
    : pageName
    ? "Save " + pageName.toLowerCase()
    : "Save";
  return (
    <footer className='settings-footer'>
      <div className='settings-page-actions'>
        <div className='discard'>
          {unsavedChanges && (
            <Button label='Discard changes' use='outline' onClick={discardChanges} />
          )}
        </div>
        <div className='save'>
          {unsavedChangesText && <p>{unsavedChangesText}</p>}
          <Button label={saveText} type='primary' onClick={saveChanges} />
        </div>
      </div>
      <KeyBoardShortcut keyCode='s' action={saveChanges} cmdKey={true} />
    </footer>
  );
}

export default SettingsFooter;
