import { Button, Editor } from "Components/index";
import Tabs from "Components/Tabs/Tabs";

function TicketReplyWindow({ replyType, setReplyType }) {
  const tabs = [
    { id: 1, value: "reply", label: "Reply", action: "Reply" },
    { id: 2, value: "note", label: "Internal Note", action: "Reply" },
  ];
  return (
    <section className='ticket-reply'>
      <header className='ticket-reply-header'>
        <div className='ticket-reply-meta'>
          <span className='reply-type draft-reply'>Draft reply</span> to{" "}
          <span className='highlight'>Matthew</span>
        </div>
        <Tabs
          tabs={tabs}
          itemSelected={replyType}
          setItemSelected={setReplyType}
          variant='default'
        />
      </header>
      <Editor variant='transparent' replyType={replyType} />
      <footer className='ticket-reply-footer'>
        <div className='ticket-actions'>
          <div className='ticket-tag-reply'>
            <Button
              onClick={() => alert("Ticket reply sent!")}
              use='text'
              label='Display Settings'
              size='medium'
              iconOnly='Replies'
            />
            <Button
              onClick={() => alert("Ticket reply sent!")}
              use='text'
              label='Display Settings'
              size='medium'
              iconOnly='TagAdd'
            />
          </div>
          <div className='ticket-reply-augments'>
            <Button
              onClick={() => alert("Ticket reply sent!")}
              use='text'
              label='Display Settings'
              size='medium'
              iconOnly='Reply'
            />
            <Button
              onClick={() => alert("Ticket reply sent!")}
              use='text'
              label='Display Settings'
              size='medium'
              iconOnly='CustomerData'
            />
            <Button
              onClick={() => alert("Ticket reply sent!")}
              use='text'
              label='Display Settings'
              size='medium'
              iconOnly='Articles'
            />
          </div>
        </div>
        <div className='ticket-reply-buttons'>
          <Button
            onClick={() => alert("Ticket reply sent!")}
            use='text'
            removePadding='inline'
            label='Send Reply'
            size='medium'
          />
        </div>
      </footer>
    </section>
  );
}

export default TicketReplyWindow;
