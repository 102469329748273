import React from "react";
import { Button } from "..";

function Tabs({ tabs, style, itemSelected, setItemSelected }) {
  const handleTabClick = (tabValue) => {
    setItemSelected(tabValue);
  };

  return (
    <ul className='tabs' data-tab-style={style}>
      {tabs.map((tab) => {
        const isSelected = tab.value === itemSelected; // Check if the tab is selected

        return (
          <li className='tab' key={tab.id}>
            <Button
              onClick={() => handleTabClick(tab.value)}
              use='text'
              removePadding='inline'
              isSelected={isSelected}
              label={tab.label}
              size='medium'
            />
          </li>
        );
      })}
    </ul>
  );
}

export default Tabs;
