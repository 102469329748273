import { FormItem } from "Components/index";
import { useGetRowsPublic } from "Hooks/";
import React, { useState } from "react";

function BillingPlans({ selectedPlan, name, onChange }) {
  const {
    data: billing_plans,
    isLoading,
    error,
  } = useGetRowsPublic({ table: "billing_plans", sortColumn: "sort_order", sortAscending: true });

  const [currentSelectedPlan, setCurrentSelectedPlan] = useState(selectedPlan);

  const handlePlanClick = (planId) => {
    setCurrentSelectedPlan(planId);
    onChange({ target: { name, value: planId } }); // Call the onChange function here
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className='billing-plans'>
      {billing_plans.map((plan) => (
        <div
          key={plan.id}
          className={`billing-plan ${
            currentSelectedPlan === plan.id ? "billing-plan-selected" : ""
          }`}
          onClick={() => handlePlanClick(plan.id)}
        >
          <header>
            <FormItem
              itemType='toggle'
              toggleType='radio'
              value={plan.id === currentSelectedPlan}
            />
            <h4>{plan.name}</h4>
            <p>{plan.price}</p>
          </header>

          <div className='billing-plan-description'>
            <p>{plan.description}</p>
          </div>
        </div>
      ))}
      <FormItem
        hidden={true}
        label='Plan ID'
        name={name}
        itemType='input'
        inputType='text'
        value={currentSelectedPlan}
        onChange={onChange}
      />
    </div>
  );
}

export default BillingPlans;
