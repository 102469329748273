import { useGetRow } from "Hooks/";

export default function FetchEndUserName({ id }) {
  const { data, isLoading, error } = useGetRow("end_user", id);

  if (isLoading || error) {
    return <p>loading</p>;
  }
  return `${data.first_name} ${data.last_name}`;
}
