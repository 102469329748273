import { Logo } from "Components/index";
import React from "react";
import { Outlet } from "react-router-dom";

function AuthLayout() {
  return (
    <div className='auth'>
      <div className='auth-container'>
        <Logo />
        <Outlet />
      </div>
    </div>
  );
}

export default AuthLayout;
