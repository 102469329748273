import React from "react";

function ChevronDown() {
  return (
    <>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M15.25 10.75L12 14.25L8.75 10.75'
      ></path>
    </>
  );
}

export default ChevronDown;
