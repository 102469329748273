import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FormBlock, FormRow, FormItem } from 'Components/';
import { Button } from 'Components/index';
import { supabase } from "Config/supabase";

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  // Get the navigate function
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      let { error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;
      // Optionally, handle the response (data)

      // On successful login, redirect to "/settings"
      navigate("/settings");
    } catch (error) {
      setError(error.error_description || error.message);
    }
  };

  return (
    <div className='auth-page-form'>
      <header>
        <h1>Log in</h1>
        <p>Welcome back!</p>
      </header>
      <div className='form'>
        <form onSubmit={handleLogin}>
          <FormBlock>
            <FormRow>
              <FormItem
                label='Email address'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                itemType='input'
                inputType='email'
                autocomplete='username'
              />
            </FormRow>
            <FormRow>
              <FormItem
                label='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                itemType='input'
                inputType='password'
                autocomplete='current-password'
              />
            </FormRow>
          </FormBlock>
          {error && <p>{error}</p>}
          <Button type='submit' label='Login' onClick={handleLogin} />
          <Link to='/forgot-password'>Forgot your password?</Link>
          <br />
          <Link to='/signup'>Don't have an account? Sign up</Link>
        </form>
      </div>
    </div>
  );
}

export default Login;
