import { useForm, useCurrentUser } from "Hooks/";
import { FormItem, FormRow, FormBlock } from "Components/";
import { PageHeader } from "Compositions/";
import { SettingsSection } from "Layouts/";
import SettingsFooter from "Compositions/SettingsFooter/SettingsFooter";
import { FileUpload } from "Components/index";
import { timezoneOptions } from "Data/timezones";
import { dayTimeOptions } from "Data/dayTimes";
function Company() {
  const { organization } = useCurrentUser();
  const id = organization;
  const table = "organizations";
  const timezones = timezoneOptions;
  const dayTimes = dayTimeOptions;
  const {
    formState,
    unsavedChanges,
    statusText,
    isLoading,
    error,
    discardChanges,
    handleFormTextChange,
    handleFormToggleChange,
    handleFormSelectChange,
    saveChanges,
    handleFileUpload,
    clearImage,
  } = useForm(table, id, {});

  const pageDefinitions = {
    title: "Company",
    icon: "Company",
    subtitle: "Subtitle",
  };

  if (isLoading || formState.name === undefined) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <div className='settings-page'>
      <PageHeader
        title={pageDefinitions.title}
        icon={pageDefinitions.icon}
        guideUrl='https://fixatehq.com'
        subtitle={pageDefinitions.subtitle}
      />
      <SettingsSection>
        <FormBlock>
          <FormRow>
            <FormItem
              label='Company name'
              name='name'
              itemType='input'
              inputType='text'
              value={formState.name}
              onChange={handleFormTextChange}
            />
          </FormRow>
          <FormRow>
            <FileUpload
              label='Company logo'
              imageUrl={formState.logo}
              altText={formState.name}
              name='logo'
              imageSize='xxLarge'
              imageStyle='rounded'
              handleFileUpload={handleFileUpload}
              clearFile={clearImage}
              helperSecondary='JPG, PNG or GIF - Max file size: 5MB - ideal size: 512px by 512px'
            />
          </FormRow>
          <FormRow>
            <FormItem
              label='Working hours'
              name='custom_timezone_enabled'
              itemType='toggle'
              toggleType='switch'
              helperPrimary={"Set targets and see analytics based on your team's working hours."}
              value={formState.custom_timezone_enabled}
              onChange={handleFormToggleChange}
            >
              {formState.custom_timezone_enabled && (
                <>
                  <FormRow>
                    <FormItem
                      label='Working Timezone'
                      name='custom_timezone'
                      itemType='input'
                      inputType='select'
                      placeholder='Select a timezone'
                      options={timezones}
                      value={timezones.find((option) => option.value === formState.custom_timezone)} // Update this line
                      onChange={handleFormSelectChange}
                    ></FormItem>
                  </FormRow>
                  <FormRow>
                    <FormItem
                      label='Day start'
                      name='day_start'
                      itemType='input'
                      inputType='select'
                      placeholder='09:00AM (default)'
                      options={dayTimes}
                      value={dayTimes.find((option) => option.value === formState.day_start)} // Update this line
                      onChange={handleFormSelectChange}
                    ></FormItem>
                    <FormItem
                      label='Day end'
                      name='day_end'
                      itemType='input'
                      inputType='select'
                      placeholder='05:30pm (default)'
                      options={dayTimes}
                      value={dayTimes.find((option) => option.value === formState.day_end)} // Update this line
                      onChange={handleFormSelectChange}
                    ></FormItem>
                  </FormRow>
                </>
              )}
            </FormItem>
          </FormRow>
        </FormBlock>
      </SettingsSection>

      <SettingsFooter
        unsavedChanges={unsavedChanges}
        discardChanges={discardChanges}
        saveChanges={saveChanges}
        pageName={pageDefinitions.title}
        unsavedChangesText={statusText}
      />
    </div>
  );
}

export default Company;
