import Icon from "../Icon/Icon";

export const slashMenuItems = [
  {
    name: "Add tag",
    execute: () => alert("Tag added successfully!"),
    aliases: ["tag", "t"],
    group: "Ticket Actions",
    icon: <Icon icon='Tags' />,
    hint: "Assigns a tag to this ticket and triggers tag-related automations",
  },
  {
    name: "Add saved reply",
    execute: () => alert("Saved reply added to the ticket!"),
    aliases: ["savedreply", "reply", "r", "sr"],
    group: "Ticket Actions",
    icon: <Icon icon='Replies' />,
    hint: "Search and attach a saved reply to this ticket",
  },
  {
    name: "I don't know",
    execute: () => alert("Ticket reassigned successfully!"),
    aliases: ["idontknow", "idk", "dontknow", "dk"],
    group: "Ticket Actions",
    icon: <Icon icon='IDontKnow' />,
    hint: "Reassigns the ticket to the next available agent",
  },
  {
    name: "Rephrase reply",
    execute: () => alert("Reply rephrased successfully!"),
    aliases: ["rephrase", "rp"],
    group: "Ticket Actions",
    icon: <Icon icon='Rephrase' />,
    hint: "Inserts a revised reply below the current one",
  },
  {
    name: "Mark as spam or junk",
    execute: () => alert("Ticket marked as spam/junk!"),
    aliases: ["spam", "junk", "markspam"],
    group: "Ticket Actions",
    icon: <Icon icon='TrashCan' />,
    hint: "Flags the ticket as spam or junk",
  },
  {
    name: "Split ticket",
    execute: () => alert("Ticket split into multiple tickets!"),
    aliases: ["split", "splt"],
    group: "Ticket Actions",
    icon: <Icon icon='GitFork' />,
    hint: "Divides this ticket into multiple smaller tickets",
  },
  {
    name: "Extend account",
    execute: () => alert("Account extended successfully!"),
    aliases: ["extend", "ext"],
    group: "Custom Actions",
    icon: <Icon icon='AlignArrowRight' />,
    hint: "Extends the account associated with this action",
  },
  {
    name: "Close account",
    execute: () => alert("Account extended successfully!"),
    aliases: ["extend", "acctextend"],
    group: "Custom Actions",
    icon: <Icon icon='CloseSquare' />,
    hint: "Cancels their subscription",
  },
  {
    name: "Reset password",
    execute: () => alert("Account extended successfully!"),
    aliases: ["reset", "pwdreset", "password"],
    group: "Custom Actions",
    icon: <Icon icon='Password' />,
    hint: "Sends a password reset email to the user",
  },
];
