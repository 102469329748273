import PageHeader from "Compositions/PageHeader/PageHeader";

function Support() {
  return (
    <div>
      <PageHeader title='Support' icon='Support' subtitle='subtitle' />
    </div>
  );
}

export default Support;
