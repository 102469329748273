import { DateTime } from "Components/index";
import React from "react";

function CellDate({ dateType, contents }) {
  return (
    <>
      <DateTime type={dateType} dateTime={contents} />
    </>
  );
}

export default CellDate;
