import { Avatar } from "Components/index";
import { useGetRow } from "Hooks/useGetRow";

function CellUserProfile({ contents, profileType }) {
  const {
    data: profile,
    isLoading: profileLoading,
    error: profileError,
  } = useGetRow("user_profiles", contents);

  if (profileLoading) {
    return <div>Loading... {contents.author}</div>;
  }

  if (profileError) {
    return <div>Error: {profileError.message}</div>;
  }

  switch (profileType) {
    case "small":
      return (
        <>
          <Avatar
            size='xSmall'
            url={profile.avatar}
            initials={profile.initials}
            profileColor={profile.profile_color}
          />
          <div className='user-profile-details' data-size='small'>
            <span className='name'>
              {profile.first_name} {profile.last_name}
            </span>
          </div>
        </>
      );
    default:
      return (
        <>
          <Avatar
            size='Small'
            url={profile.avatar}
            initials={profile.initials}
            profileColor={profile.profile_color}
          />
          <div className='user-profile-details'>
            <span className='name'>
              {profile.first_name} {profile.last_name}
            </span>
            <span className='email'>{profile.email}</span>
          </div>
        </>
      );
  }
}

export default CellUserProfile;
