import { DateTime, Tag } from "Components/index";
import { useGetRows } from "Hooks/useGetRows";
import React, { useEffect, useState } from "react";

function formatNames(names) {
  if (names.length === 0) {
    return ""; // No names to display
  } else if (names.length === 1) {
    return names[0]; // Only one name, no need for 'and'
  } else if (names.length === 2) {
    return names.join(" and "); // Two names, separate with 'and'
  } else {
    // More than two names, join all but the last with a comma, and add 'and' before the last name
    const last = names.pop();
    return `${names.join(", ")} and ${last}`;
  }
}

function TicketTagAdded({ tags }) {
  const [userNames, setUserNames] = useState([]);
  const { data, isLoading, error } = useGetRows({ table: "user_profiles" });

  useEffect(() => {
    if (data) {
      // Create a map of user IDs to user names
      const userMap = {};
      data.forEach((user) => {
        userMap[user.id] = `${user.first_name}`;
      });

      // Use the map to get user names for each added_by ID and de-dupe the names
      const tagUserNames = [...new Set(tags.map((tag) => userMap[tag.added_by]))];
      setUserNames(tagUserNames);
    }
  }, [data, tags]);

  // Determine the newest tag's dateTime
  const newestTag = tags.reduce((newest, current) => {
    return new Date(current.created_at) > new Date(newest.created_at) ? current : newest;
  }, tags[0]);

  if (isLoading) {
    return null;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <section className='ticket-tag-added'>
      <div className='message'>
        <span className='user'>{formatNames(userNames)}</span>
        added the {tags.length > 1 ? "tags " : "tag "}
        <div className='tag-list'>
          {tags.map((tag) => (
            <Tag key={tag.id} id={tag.tag} showKey={false} addedBy={userNames[tags.indexOf(tag)]} />
          ))}
        </div>
      </div>
      <DateTime dateTime={newestTag.created_at} />
    </section>
  );
}

export default TicketTagAdded;
