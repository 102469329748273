import { SettingsSection } from "Layouts/index";

function BillingPlanSelector() {
  return (
    <SettingsSection>
      <h3>Select a plan</h3>
    </SettingsSection>
  );
}

export default BillingPlanSelector;
