import * as CellContents from "Components/Table/CellContents/";
import { Link } from "react-router-dom";

function toPascalCase(str) {
  return str
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");
}

function TableCell({ contents, link, cellType, colSpan, type, ...props }) {
  const ContentComponent = CellContents["Cell" + toPascalCase(type)] || CellContents.CellText;
  switch (type) {
    case "header":
      return (
        <th className={`cell-${type}`} data-cell-type={cellType} colSpan={colSpan}>
          <div className={`cell-contents cell-contents-${type}`}>
            <ContentComponent contents={contents} {...props} />
          </div>
        </th>
      );
    default:
      return (
        <td className={`cell-${type}`} colSpan={colSpan}>
          {link ? ( // Check if the 'link' prop is provided
            <Link to={link} className='cell-link'>
              <div className={`cell-contents cell-contents-${type}`}>
                <ContentComponent contents={contents} {...props} />
              </div>
            </Link>
          ) : (
            <div className={`cell-contents cell-contents-${type}`}>
              <ContentComponent contents={contents} {...props} />
            </div>
          )}
        </td>
      );
  }
}
 
export default TableCell;
