import React, { useState, useEffect } from "react";
import { ShortcutLetters, ShortcutNumbers } from "Data/ShortcutKeys";
import { FormItem } from "..";
import { useGetRows } from "Hooks/useGetRows";

function MultiPicker({ value, onChange, name, table }) {
  const { data, isLoading, error } = useGetRows({
    table: table,
    sortColumn: "updated_at",
    sortAscending: false,
  });

  const [selectedValue, setSelectedValue] = useState(value);
  const initialExcludeKeys = data ? data.map((item) => item.shortcut_key) : [];

  const [excludeKeys, setExcludeKeys] = useState(initialExcludeKeys);

  const handleItemClick = (value) => {
    if (!excludeKeys.includes(value)) {
      setSelectedValue(value);
      onChange({ target: { name, value: value } });
    }
  };

  // Reset selectedValue when the value prop changes
  useEffect(() => {
    setSelectedValue(value);
  }, [value, setSelectedValue]);

  useEffect(() => {
    if (data) {
      // Extract the shortcut_key values and use them as the exclude list
      setExcludeKeys(data.map((item) => item.shortcut_key));
      const updatedExcludeKeys = excludeKeys.filter((key) => key !== selectedValue);
      setExcludeKeys(updatedExcludeKeys);
    }
  }, [data, selectedValue, excludeKeys, setExcludeKeys]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div>
      <FormItem
        label='Selected Value'
        disabled
        value={selectedValue}
        onChange={onChange}
        name={name}
      />
      <div className='multi-picker'>
        <ul>
          {ShortcutLetters.map((key) => (
            <li
              key={key.value}
              className={`${
                selectedValue !== key.value && excludeKeys.includes(key.value) ? "used" : ""
              } ${key.value === selectedValue ? "selected" : ""}`}
              onClick={() => handleItemClick(key.value)}
            >
              <span>{key.label}</span>
            </li>
          ))}
        </ul>
        <ul>
          {ShortcutNumbers.map((key) => (
            <li
              key={key.value}
              className={`${
                selectedValue !== key.value && excludeKeys.includes(key.value) ? "used" : ""
              } ${key.value === selectedValue ? "selected" : ""}`}
              onClick={() => handleItemClick(key.value)}
            >
              <span>{key.label}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default MultiPicker;
