import React from "react";
import { useGetRows } from "Hooks/";

function CategoryTag({ id, showKey = false }) {
  const {
    data: tagCategories,
    isLoading: tagCategoriesLoading,
    error: tagCategoriesError,
  } = useGetRows({ table: "tag_categories" });

  if (tagCategoriesLoading || tagCategoriesError) {
    return null;
  }

  const tagCategory = tagCategories.find((category) => category.id === id);

  if (!tagCategory) {
    return null;
  }

  return (
    <div className='tag' data-tag-type={tagCategory.slug} title={tagCategory.name}>
      {showKey ? <span className='shortcut-key'>{tagCategory.shortcut_key}</span> : null}
      <span className='label'>{tagCategory.name}</span>
    </div>
  );
}

export default CategoryTag;
