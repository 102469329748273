export const ShortcutLetters = [
  ...Array.from({ length: 26 }, (_, i) => ({
    value: String.fromCharCode(65 + i),
    label: String.fromCharCode(65 + i),
  })),
];

export const ShortcutNumbers = [
  ...Array.from({ length: 10 }, (_, i) => ({
    value: String(i),
    label: String(i),
  })),
];
