import PageHeader from "Compositions/PageHeader/PageHeader";

function Articles() {
  return (
    <div>
      <PageHeader title='Articles' icon='Articles' subtitle='subtitle' />
    </div>
  );
}

export default Articles;
