import Icon from "Components/Icon/Icon";
import { Button } from "Components/index";
import React from "react";

function PageHeader({ title, subtitle, icon, guideUrl }) {
  return (
    <header className='page-header'>
      <div className='title'>
        {icon ? <Icon icon={icon} /> : null}
        {title ? <h2>{title}</h2> : null}
        {subtitle ? <p>{subtitle}</p> : null}
      </div>
      {guideUrl ? (
        <div className='actions'>
          <Button url={guideUrl} label='view guide' use='outline' iconLead='Tickets' />
        </div>
      ) : null}
    </header>
  );
}

export default PageHeader;
