import { useGetRowGlobal } from "Hooks/";

export default function FetchProfileColor(id) {
  const { data, isLoading, error } = useGetRowGlobal("profile_colors", id);

  if (isLoading || error) {
    return null;
  }

  return `${data.color}-${data.shade}`;
}
