import PageHeader from "Compositions/PageHeader/PageHeader";

function TicketSettings() {
  return (
    <div className='settings-page'>
      <PageHeader title='Ticket settings' icon='TicketSettings' subtitle='subtitle' />
    </div>
  );
}

export default TicketSettings;
