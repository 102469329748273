import * as Icons from "./Icons";

const Icon = ({ icon, size, ...props }) => {
  const IconComponent = Icons[icon];
  if (!icon) return null;
  if (!IconComponent) {
    console.error(`Icon ${icon} could not be found`);
    return null;
  }

  return (
    <span className='icon-container' data-size={size}>
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        data-position={props.position}
        xmlns='http://www.w3.org/2000/svg'
      >
        <IconComponent {...props} />
      </svg>
    </span>
  );
};

export default Icon;
  