import { Button } from "Components/";
import React, { useCallback, useState } from "react";

const ColorModeSetter = () => {
  const [loadingLight, setLoadingLight] = useState(false);
  const [loadingDark, setLoadingDark] = useState(false);
  const [loadingSystem, setLoadingSystem] = useState(false);
  const minLoadTime = 300;

  const setLightMode = useCallback(async () => {
    let timeoutId = setTimeout(() => setLoadingLight(true), minLoadTime);

    // Simulate a delay
    //await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 seconds delay

    document.body.setAttribute("data-theme", "light");

    clearTimeout(timeoutId);
    setLoadingLight(false);
  }, []);

  const setDarkMode = useCallback(async () => {
    let timeoutId = setTimeout(() => setLoadingDark(true), minLoadTime);
    // Here you would perform the asynchronous operation to store the setting in the database.
    document.body.setAttribute("data-theme", "dark");
    clearTimeout(timeoutId);
    setLoadingDark(false);
  }, []);

  const setSystemMode = useCallback(async () => {
    let timeoutId = setTimeout(() => setLoadingSystem(true), minLoadTime);
    // Here you would perform the asynchronous operation to store the setting in the database.
    document.body.removeAttribute("data-theme");
    clearTimeout(timeoutId);
    setLoadingSystem(false);
  }, []);

  return (
    <div className='color-mode-setter'>
      <Button
        onClick={setLightMode}
        label='Set light mode'
        iconOnly='Light'
        use='text'
        size='small'
        loading={loadingLight}
      />
      <Button
        onClick={setDarkMode}
        use='text'
        size='small'
        label='Set dark mode'
        iconOnly='Dark'
        loading={loadingDark}
      />
      <Button
        onClick={setSystemMode}
        label='Set system mode'
        use='text'
        size='small'
        iconOnly='System'
        loading={loadingSystem}
      />
    </div>
  );
};

export default ColorModeSetter;
