import { Logo, SidebarMenu } from "Components/";
import { QueueStatus, SidebarProfile, SidebarToggle } from "Compositions/";
import { NavItemsPrimary, NavItemsSecondary } from "Config/navItems";

function SidebarPrimary() {
  return (
    <div className='sidebar sidebar-primary' data-theme='dark'>
      <div className='primary-sidebar-top'>
        <header className='sidebar-primary-header'>
          <Logo />
          <SidebarToggle icon='Menu' />
        </header>
        <SidebarMenu location='sidebar-primary' name='primary' navItems={NavItemsPrimary} />
      </div>
      <div className='primary-sidebar-bottom'>
        <SidebarMenu location='sidebar-primary' name='secondary' navItems={NavItemsSecondary} />
        <QueueStatus />
        <SidebarProfile />
      </div>
    </div>
  );
}

export default SidebarPrimary;
