import { useQuery } from "@tanstack/react-query";
import { supabase } from "Config/supabase";
import { useCurrentUser } from "Hooks/";

export function useGetRow(table, id = null, successCallback) {
  const { organization } = useCurrentUser();
  const orgId = organization;

  return useQuery({
    staleTime: 1 * (60 * 1000),
    enabled: !!id && !!orgId,
    queryKey: [table, id],
    queryFn: async () => {
      let query = supabase.from(table).select("*").eq("id", id);

      if (table !== "organizations" && table !== "tag_categories") {
        query = query.eq("organization", orgId);
      }

      const { data, error } = await query.single();

      if (error) throw error;
      if (successCallback) successCallback(data);
      return data;
    },
  });
}
