import Avatar from "Components/Avatar/Avatar";
import { useState, useRef } from "react";
import { useGetRow } from "Hooks/";
import { useCurrentUser } from "Hooks/useCurrentUser";
import { Button, StatusDot, Popover } from "Components/";
import { createPortal } from "react-dom";
import { ColorModeSetter } from "..";

function SidebarProfile() {
  const { id } = useCurrentUser();
  const { data, isLoading, error } = useGetRow("user_profiles", id);
  const [displaySettingsVisible, setDisplaySettingsVisible] = useState(false);

  // Create a ref for the button that opens the popover
  const buttonRef = useRef(null);

  // Function to toggle popover visibility
  const toggleDisplaySettingsPopover = () => {
    setDisplaySettingsVisible(!displaySettingsVisible);
  };

  if (isLoading || error) {
    return null;
  }

  return (
    <>
      <div className='sidebar-profile'>
        <div className='sidebar-profile-avatar'>
          <Avatar
            url={data.avatar}
            name={`${data.first_name} ${data.last_name}`}
            initials={data.initials}
            size='small'
            profileColor={data.profile_color}
          />
        </div>
        <div className='sidebar-profile-information'>
          <span className='name'>{data.first_name + " " + data.last_name}</span>
          <span className='status'>
            <StatusDot status='online' />
            Answering tickets
          </span>
        </div>
        <div className='sidebar-profile-actions'>
          <Button url='/logout' use='text' label='logout' size='small' iconOnly='Logout' />
          <Button
            onClick={toggleDisplaySettingsPopover}
            use='text'
            label='Display Settings'
            size='small'
            iconOnly='Cog'
            ref={buttonRef}
          />
        </div>
      </div>
      {displaySettingsVisible &&
        createPortal(
          <Popover
            position='display-settings'
            isVisible={displaySettingsVisible}
            setIsVisible={setDisplaySettingsVisible}
            // Pass the buttonRef as the buttonRef prop
            buttonRef={buttonRef}
          >
            <h4>Display settings</h4>
            <ColorModeSetter />
          </Popover>,
          document.body
        )}
    </>
  );
}

export default SidebarProfile;
