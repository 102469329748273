import TableCell from "Components/Table/structure/TableCell";
import { Icon } from "Components/index";

function SavedReplyRow({ type, row }) {
  switch (type) {
    case "header":
      return (
        <tr>
          <TableCell
            type='header'
            cellType='shortcutKey'
            contents={<Icon icon='ShortcutKey' size='small' />}
          />
          <TableCell type='header' contents='Title' />
          <TableCell type='header' contents='Last updated' />
          <TableCell type='header' contents='Author' />
          <TableCell type='header' contents='' />
        </tr>
      );
    default:
      return (
        <tr>
          <TableCell
            link={`/replies/${row.slug}`}
            type='shortcut-key'
            subKey={false}
            contents={row.shortcut_key}
          />
          <TableCell link={`/replies/${row.slug}`} type='reply-details' contents={row} />
          <TableCell
            link={`/replies/${row.slug}`}
            type='date'
            dateType='relative'
            contents={row.updated_at}
          />
          <TableCell
            link={`/replies/${row.slug}`}
            type='user-profile'
            profileType='small'
            contents={row.author}
          />
          <TableCell type='ellipsis' contents='hello' />
        </tr>
      );
  }
}


export default SavedReplyRow;
