import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import * as Page from "Pages/index";
import * as Layout from "Layouts/index";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route element={<Layout.AppLayout />}>
            <Route path='/' element={<Navigate to='tickets' />} />
            <Route path='/tickets' element={<Page.Tickets />}>
              <Route path=':slug' element={<Page.Tickets />} />
            </Route>
            <Route path='dashboard' element={<Page.Dashboard />} />
            <Route path='queue' element={<Page.Queue />} />
            <Route path='history' element={<Page.History />} />
            <Route path='reports' element={<Page.Reports />} />
            <Route path='replies' element={<Page.SavedReplies />}>
              <Route element={<Layout.PanelLayout />}>
                <Route path=':slug' element={<Page.SavedReplyItem />} />
              </Route>
            </Route>
            <Route path='customers' element={<Page.Customers />} />
            <Route path='articles' element={<Page.Articles />} />
            <Route path='support' element={<Page.Support />} />
            <Route element={<Layout.SettingsLayout />}>
              <Route path='/settings' element={<Navigate to='your-account' />} />
              <Route path='/settings/your-account' element={<Page.YourAccount />} />
              <Route path='/settings/team' element={<Page.Team />}>
                <Route element={<Layout.PanelLayout />}>
                  <Route path=':slug' element={<Page.TeamItem />} />
                </Route>
              </Route>

              <Route path='/settings/company' element={<Page.Company />} />
              <Route path='/settings/customer-data' element={<Page.CustomerData />} />
              <Route path='/settings/integrations' element={<Page.Integrations />} />
              <Route path='/settings/notifications' element={<Page.Notifications />} />
              <Route path='/settings/policies' element={<Page.Policies />} />
              <Route path='/settings/ticket' element={<Page.TicketSettings />} />
              <Route path='/settings/tags' element={<Page.Tags />}>
                <Route element={<Layout.PanelLayout />}>
                  <Route path=':slug' element={<Page.TagItem />} />
                </Route>
              </Route>
              <Route path='/settings/billing' element={<Page.Billing />} />
            </Route>
          </Route>
          <Route element={<Layout.AuthLayout />}>
            <Route path='login' element={<Page.Login />} />
            <Route path='logout' element={<Page.Logout />} />
            <Route path='signup/verify' element={<Page.VerifyEmail />} />
            <Route path='signup' element={<Page.Signup />} />
            <Route path='forgot-password' element={<Page.ForgotPassword />} />
          </Route>
        </Routes>
      </Router>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;
