import BillingPaymentMethod from "./BillingPaymentMethod";
import BillingInvoices from "./BillingInvoices";
import { useForm, useCurrentUser } from "Hooks/";
import { SettingsSection } from "Layouts/";
import { SettingsFooter, BillingPlans, PageHeader } from "Compositions/";
import { FormBlock, FormItem, FormRow } from "Components/index";

function Billing() {
  const { organization } = useCurrentUser();
  const id = organization;
  const table = "organizations";
  const {
    formState,
    unsavedChanges,
    statusText,
    isLoading,
    error,
    discardChanges,
    handleFormTextChange,
    handleFormToggleChange,

    saveChanges,
  } = useForm(table, id, {});
  const websiteUrl = process.env.REACT_APP_WEBSITE_URL || "https://withhark.com";

  const pageDefinitions = {
    title: "Billing",
    icon: "Billing",
    subtitle: "Subtitle",
  };

  if (isLoading || formState.name === undefined) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <div className='settings-page'>
      <PageHeader
        title={pageDefinitions.title}
        icon={pageDefinitions.icon}
        guideUrl='https://fixatehq.com'
        subtitle={pageDefinitions.subtitle}
      />
      <SettingsSection
        title='Your plan'
        subtitle={
          <>
            See a full comparison of our plans on our{" "}
            <a target='_blank' rel='noreferrer' href={websiteUrl + "/pricing"}>
              {" "}
              {/* Use the environment variable here */}
              pricing page
            </a>
          </>
        }
      >
        <BillingPlans
          name='billing_plan'
          selectedPlan={formState.billing_plan}
          onChange={handleFormTextChange}
        />
      </SettingsSection>
      <SettingsSection title='Billing notifications' subtitle='Where should invoices be sent?'>
        <FormBlock>
          <FormRow>
            <FormItem
              label='My email address'
              name='billing_notify_self'
              itemType='toggle'
              toggleType='checkbox'
              helperPrimary={"We'll send you a copy of all invoices."}
              value={formState.billing_notify_self}
              onChange={handleFormToggleChange}
            />
          </FormRow>
          <FormRow>
            <FormItem
              label='Other people'
              name='billing_notify_others'
              itemType='toggle'
              toggleType='checkbox'
              helperPrimary={"Separate additional email addresses with a comma"}
              value={formState.billing_notify_others}
              onChange={handleFormToggleChange}
            >
              {formState.billing_notify_others && (
                <>
                  <FormRow>
                    <FormItem
                      label='Email address(es)'
                      name='billing_notify_others_addresses'
                      itemType='input'
                      inputType='text'
                      placeholder='e.g. janet@yourco.com, jack@yourco.com, invoices@yourco.com'
                      helperPrimary='each of these addresses will get all billing notifications'
                      value={formState.billing_notify_others_addresses}
                      onChange={handleFormTextChange}
                    />
                  </FormRow>
                </>
              )}
            </FormItem>
          </FormRow>
        </FormBlock>
      </SettingsSection>
      <BillingPaymentMethod
        name='billing_default_card'
        defaultCard={formState.billing_default_card}
        onChange={handleFormTextChange}
      />
      <BillingInvoices />
      <SettingsFooter
        unsavedChanges={unsavedChanges}
        discardChanges={discardChanges}
        saveChanges={saveChanges}
        pageName={pageDefinitions.title}
        unsavedChangesText={statusText}
      />
    </div>
  );
}

export default Billing;
