import TicketMessages from "Components/Table/TicketLatestMessage";
import TicketTags from "Components/Tag/TicketTags";
import { useGetRow } from "Hooks/useGetRow";
import React from "react";

function CellTicket({ contents }) {
  const {
    data: ticket,
    isLoading: ticketLoading,
    error: ticketError,
  } = useGetRow("tickets", contents);

  if (ticketLoading) {
    return <div>Loading...</div>;
  }

  if (ticketError) {
    return <div>Error: {ticketError.message}</div>;
  }

  return (
    <>
      <div className='ticket-number'>T{ticket.ticket_number}</div>
      <div className='ticket-title'>{ticket.title}</div>
      <TicketMessages ticketId={ticket.id} />

      <div className='ticket-tags'>
        <div className='ticket-tags'>
          <TicketTags ticketId={ticket.id} />
        </div>
      </div>
    </>
  );
}

export default CellTicket;
