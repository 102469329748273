import React from "react";

function Tags() {
  return (
    <>
      <circle cx='15' cy='9' r='1' fill='currentColor'></circle>
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.5'
        d='M12 4.75H19.25V12L12.5535 18.6708C11.7544 19.4668 10.4556 19.445 9.68369 18.6226L5.28993 13.941C4.54041 13.1424 4.57265 11.8895 5.36226 11.1305L12 4.75Z'
      ></path>
    </>
  );
}

export default Tags;
