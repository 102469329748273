import { Avatar, Tag, TicketTags, DateTime } from "Components/index";
import { useGetSlugRow } from "Hooks/useGetSlugRow";
import React from "react";

function TicketHeader({ slug }) {
  const {
    data: ticket,
    isLoading: ticketLoading,
    error: ticketError,
  } = useGetSlugRow("tickets", slug);

  if (ticketLoading) {
    return <div>Loading...</div>;
  }

  if (ticketError) {
    return <div>Error: {ticketError.message}</div>;
  }

  return (
    <>
      <header className='ticket-header'>
        <div className='ticket-meta'>
          <span className='ticket-number'>{slug}</span>
          <TicketTags ticketId={ticket.id} />
        </div>
        <div className='ticket-status'>
          <div className='ticket-viewers'>
            <Avatar size='xSmall' id='c8734cc7-f2a6-4e20-85f0-341ffb136c1e' />
            <p>Just you</p>
          </div>
          <div className='ticket-status-tag'>
            <Tag id='77ee7e20-13d3-460f-884e-f8eef7b331d9' />
          </div>
        </div>
      </header>
      <header className='message-title'>
        <h2>Can't Run Payroll</h2>
        <DateTime dateTime='2023-09-26T12:00:00Z' />
      </header>
    </>
  );
}

export default TicketHeader;
