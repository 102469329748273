import React from "react";

function CellShortcutKey({ contents, ...props }) {
  return (
    <>
      {props.subKey ? "┗" : null} {contents}
    </>
  );
}

export default CellShortcutKey;
