import { useGetTicketDataRows } from "Hooks/";
import React from "react";
import { Icon } from "Components/";
import { DateTime } from "..";

function TicketMessages({ ticketId }) {
  const {
    data: messages,
    isLoading: messagesLoading,
    error: messagesError,
  } = useGetTicketDataRows({
    table: "ticket_messages",
    id: ticketId,
    sortColumn: "created_at",
    sortAscending: false,
  });

  if (messagesLoading) {
    return null;
  }

  if (messagesError) {
    return <div>Error: {messagesError.message}</div>;
  }

  if (messages.length === 0) {
    return <div>No messages</div>;
  }

  // Load only the first message from the array
  const lastMessage = messages[0];

  return (
    <>
      <span className='latest-message-type'>
        <Icon icon={lastMessage.type} size='small' />
      </span>
      <DateTime type='relative-short' dateTime={lastMessage.created_at} />
      <div className='ticket-messag-excerpt'>{lastMessage.message}</div>
    </>
  );
}

export default TicketMessages;
