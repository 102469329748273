import { useGetTicketDataRows } from "Hooks/";
import React from "react";
import { Tag } from "..";

function TicketTags({ ticketId }) {
  const {
    data: tags,
    isLoading: tagsLoading,
    error: tagsError,
  } = useGetTicketDataRows({
    table: "ticket_tags",
    id: ticketId,
  });

  if (tagsLoading) {
    return null;
  }

  if (tagsError) {
    return <div>Error: {tagsError.message}</div>;
  }

  if (tags.length === 0) {
    return <div>No tags</div>;
  }

  return (
    <>
      {tags.map((tag) => (
        <Tag key={tag.tag} id={tag.tag} />
      ))}
    </>
  );
}

export default TicketTags;
