import React from "react";
import { useGetRows } from "Hooks/";

function Tag({ id, onClick, showKey = false }) {
  const {
    data: tagsData,
    isLoading: tagsLoading,
    error: tagsError,
  } = useGetRows({ table: "tags" });
  const {
    data: tagCategoriesData,
    isLoading: tagCategoriesLoading,
    error: tagCategoriesError,
  } = useGetRows({ table: "tag_categories" });

  if (tagsLoading || tagCategoriesLoading || tagsError || tagCategoriesError) {
    return null;
  }

  const tag = tagsData.find((tag) => tag.id === id);
  const tagCategory = tagCategoriesData.find((category) => category.id === tag?.tag_category);

  return tag && tagCategory ? (
    <div
      className='tag'
      data-tag-type={tagCategory.slug}
      title={tagCategory.name}
      onClick={onClick}
    >
      {showKey ? <span className='shortcut-key'>{tag.shortcut_key}</span> : null}
      <span className='label'>{tag.name}</span>
    </div>
  ) : null;
}

export default Tag;
 