import React, { useState } from "react";
import CategoryTag from "Components/Tag/CategoryTag";
import { Button, FormItem, Tag } from "Components/index";
import { useGetRows } from "Hooks/useGetRows";

function AddTagWindow({ ticketId }) {
  const [visibleCategory, setVisibleCategory] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const {
    data: tagsData,
    isLoading: tagsLoading,
    error: tagsError,
  } = useGetRows({ table: "tags" });
  const {
    data: tagCategoriesData,
    isLoading: tagCategoriesLoading,
    error: tagCategoriesError,
  } = useGetRows({ table: "tag_categories" });

  const toggleTag = (tagId) => {
    setSelectedTags((prevTags) => {
      if (prevTags.includes(tagId)) {
        return prevTags.filter((id) => id !== tagId);
      }
      return [...prevTags, tagId];
    });
  };

  if (tagsLoading || tagCategoriesLoading) {
    return <p>Loading...</p>;
  }

  if (tagsError || tagCategoriesError) {
    return (
      <p>
        Error: {tagsError?.message} {tagCategoriesError?.message}
      </p>
    );
  }

  return (
    <div className='modal add-tag-window'>
      <header className='modal-header'>
        <h4>Add tag</h4>
        <Button
          iconOnly='Close'
          use='text'
          label='close'
          onClick={() => alert("Ticket reply sent!")}
        />
      </header>
      <div className='modal-content'>
        <FormItem label='Tag name' placeholder='press return to search tags' />
        <div className='add-tag-list'>
          {visibleCategory === null ? (
            tagCategoriesData.map((category) => (
              <div
                key={category.id}
                className='category'
                onClick={() => setVisibleCategory(category.id)}
              >
                <CategoryTag id={category.id} showKey={true} />
              </div>
            ))
          ) : (
            <>
              <div className='category selected-category' onClick={() => setVisibleCategory(null)}>
                <CategoryTag
                  id={tagCategoriesData.find((cat) => cat.id === visibleCategory).id}
                  showKey={true}
                />
              </div>
              <div className='tag-category-list'>
                {tagsData
                  .filter((tag) => tag.tag_category === visibleCategory)
                  .map((row) => (
                    <div className='tag-category-list-item' key={row.id}>
                      <Tag
                        key={row.id}
                        id={row.id}
                        showKey={true}
                        onClick={() => toggleTag(row.id)}
                      />
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
        <div className='tag-list-selected'>
          Selected Tags:
          {selectedTags.map((tagId) => (
            <Tag key={tagId} id={tagId} onClick={() => toggleTag(tagId)} />
          ))}
          <Button label='Add Tags' />
        </div>
      </div>
    </div>
  );
}

export default AddTagWindow;
