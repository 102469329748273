import { useCreateRow, useCurrentUser } from "Hooks/";
import { supabase } from "Config/supabase";

import { useQuery, useMutation } from "@tanstack/react-query";
import { Button, DateTime, FormItem } from "Components/index";

function InviteTeamMember() {
  const { organization } = useCurrentUser();
  const createRow = useCreateRow("invitations");

  // Query for existing invite, only if organization exists
  const { data: invites, refetch } = useQuery(
    ["invitations", organization],
    async () => {
      const { data, error } = await supabase
        .from("invitations")
        .select("*")
        .eq("organization", organization);
      if (error) throw error;
      return data;
    },
    {
      staleTime: 10 * (60 * 1000),
      enabled: !!organization,
    }
  );
  const invite = invites ? invites[0] : null;

  const handleCreateInvite = async () => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 90);

    try {
      await createRow.mutate({
        organization,
        expiry_date: expiryDate.toISOString(),
      });
      refetch();
    } catch (error) {
      console.error("Error creating invite:", error);
    }
  };
  const deleteInviteMutation = useMutation(
    async () => {
      if (!organization) throw new Error("Organization not available.");
      const { error } = await supabase
        .from("invitations")
        .delete()
        .eq("organization", organization);
      if (error) throw error;
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const handleDeleteInvite = async () => {
    await deleteInviteMutation.mutate();
  };

  const copyInvite = () => {
    if (!invite) return;

    const inviteLink = `https://fixatehq.com/invite/${invite.token}`;
    navigator.clipboard
      .writeText(inviteLink)
      .then(() => {
        console.log("Invite link copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy invite link: ", err);
      });
  };

  return (
    <div className='invite-team-member'>
      <header>
        <h4>Invite team member</h4>
        <p>Invite a team member to join your organization.</p>
      </header>

      {invite ? (
        <>
          <div className='invite-team-member-invite'>
            <div className='invite-team-member-invite-link'>
              <span className='helper-primary'>
                expires <DateTime dateTime={invite.expiry_date} />
              </span>
              <FormItem
                itemType='input'
                inputType='text'
                disabled={true}
                value={`${process.env.REACT_APP_DOMAIN}/invite/${invite.token}`}
                readOnly={true}
              />
              <Button iconOnly='Copy' use='outline' onClick={copyInvite} label='Copy link' />
              <Button
                iconOnly='Trash'
                use='destructive'
                onClick={handleDeleteInvite}
                label='Delete invite'
              />
            </div>
          </div>
        </>
      ) : (
        <Button onClick={handleCreateInvite} use='subtle' label='Create invite' />
      )}
    </div>
  );
}

export default InviteTeamMember;
