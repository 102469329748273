import React from "react";

function Fixate() {
  return (
    <>
      <path
        d='M3.75 8.20209V7.16608C3.75 3.62091 6.6167 0.75 10.1492 0.75H17.8273C19.1636 0.75 20.25 1.83638 20.25 3.18021C20.25 4.33184 19.4512 5.29613 18.381 5.54682H9.62045H9.61332L9.6062 5.54696L9.16984 5.55525L9.15965 5.55544L9.14946 5.55591C6.86302 5.66156 5.05887 6.67399 3.75 8.20209ZM10.9192 8.44619C9.56325 9.85468 8.69836 11.6757 8.60374 13.7189L8.60368 13.7189L8.60337 13.7279L8.59591 13.9461L8.59548 13.9589V13.9717L8.59547 20.8198C8.59547 22.1636 7.50912 23.25 6.17274 23.25C4.83636 23.25 3.75 22.1636 3.75 20.8198L3.75 14.9829C3.75 14.9829 3.75 14.9829 3.75 14.9828C3.75005 14.6543 3.77405 14.3318 3.82024 14.017L3.93139 13.2593C4.56921 10.5007 7.03673 8.44611 9.98002 8.44611C10.293 8.44611 10.6061 8.44615 10.9192 8.44619ZM15.8739 8.44611C17.2103 8.44611 18.2966 9.53249 18.2966 10.8763C18.2966 12.2202 17.2103 13.3065 15.8739 13.3065H11.584C11.9564 11.1184 13.4219 9.30284 15.3979 8.44611H15.8739Z'
        stroke='currentColor'
        strokeWidth='1.5'
      />
    </>
  );
}

export default Fixate;
