import { useForm, useCurrentUser } from "Hooks/";
import { FormItem, FormRow, FormBlock } from "Components/";
import { PageHeader } from "Compositions/";
import { SettingsSection } from "Layouts/";
import SettingsFooter from "Compositions/SettingsFooter/SettingsFooter";

function Policies() {
  const { organization } = useCurrentUser();
  const id = organization;
  const table = "organizations";

  const {
    formState,
    unsavedChanges,
    statusText,
    isLoading,
    error,
    discardChanges,
    handleFormTextChange,
    handleFormToggleChange,
    saveChanges,
  } = useForm(table, id, {});

  const pageDefinitions = {
    title: "Policies",
    icon: "Policies",
    subtitle: "Subtitle",
  };

  if (isLoading || formState.name === undefined) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <div className='settings-page'>
      <PageHeader
        title={pageDefinitions.title}
        guideUrl='https://fixatehq.com'
        icon={pageDefinitions.icon}
        subtitle={pageDefinitions.subtitle}
      />
      <SettingsSection>
        <FormBlock>
          <FormRow>
            <FormItem
              label='Max response time'
              name='max_response_enabled'
              itemType='toggle'
              toggleType='switch'
              helperPrimary={
                "Set a max allowable response time. Tickets approaching this time will trigger an alert to get additional agents online."
              }
              value={formState.max_response_enabled}
              onChange={handleFormToggleChange}
            >
              {formState.max_response_enabled && (
                <>
                  <FormRow>
                    <FormItem
                      label='During work hours'
                      name='max_response_time'
                      itemType='range'
                      min={0.5}
                      max={48}
                      step={0.5}
                      value={formState.max_response_time} // Update this line
                      onChange={handleFormTextChange}
                    ></FormItem>
                  </FormRow>
                  <FormRow>
                    <FormItem
                      label='Outside work hours'
                      name='max_response_time_outside_hours'
                      itemType='range'
                      min={0.5}
                      max={48}
                      step={0.5}
                      value={formState.max_response_time_outside_hours} // Update this line
                      onChange={handleFormTextChange}
                    ></FormItem>
                  </FormRow>
                </>
              )}
            </FormItem>
          </FormRow>
          <FormRow>
            <FormItem
              label='Same-agent reply'
              name='same_agent_reply_enabled'
              itemType='toggle'
              toggleType='switch'
              helperPrimary={
                "Should the same agent respond to the same ticket. If not, we'll assign it to the next available agent."
              }
              helperSecondary={"We recommend setting this to 'flexible' or leaving it disabled."}
              value={formState.same_agent_reply_enabled}
              onChange={handleFormToggleChange}
            >
              {formState.same_agent_reply_enabled && (
                <>
                  <FormRow>
                    <FormItem
                      label='During work hours'
                      name='max_response_time'
                      itemType='range'
                      min={0.5}
                      max={48}
                      step={0.5}
                      placeholder='Select a timezone'
                      value={formState.max_response_time} // Update this line
                      onChange={handleFormTextChange}
                    ></FormItem>
                  </FormRow>
                </>
              )}
            </FormItem>
          </FormRow>
        </FormBlock>
      </SettingsSection>
      <SettingsFooter
        unsavedChanges={unsavedChanges}
        discardChanges={discardChanges}
        saveChanges={saveChanges}
        pageName={pageDefinitions.title}
        unsavedChangesText={statusText}
      />
    </div>
  );
}

export default Policies;
