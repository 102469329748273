import { useEffect } from "react";

const KeyboardShortcut = ({ keyCode, action, cmdKey }) => {
  useEffect(() => {
    const keyHandler = (event) => {
      // on Mac, event.metaKey is the command key
      if (event.key === keyCode && event.metaKey === cmdKey) {
        action();
      }
    };

    // Add the keydown listener
    window.addEventListener("keydown", keyHandler);

    // Make sure to remove the listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", keyHandler);
    };
  }, [keyCode, action, cmdKey]); // Recreate the event listener when any of these values changes

  return null; // This component doesn't render anything
};

export default KeyboardShortcut;
