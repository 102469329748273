import { Button } from "Components/index";
import React, { useEffect, useCallback } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

function PanelLayout() {
  const location = useLocation();
  const navigate = useNavigate();

  // Wrap the handleClose function in useCallback
  const handleClose = useCallback(() => {
    // Get the current pathname
    const currentPathname = location.pathname;

    // Split the pathname by '/' and remove the last part
    const parts = currentPathname.split("/");
    parts.pop();

    // Join the parts back together to get the new pathname
    const newPathname = parts.join("/");

    // Navigate to the new pathname
    navigate(newPathname);
  }, [location, navigate]);

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    // Add event listener for the Escape key
    document.addEventListener("keydown", handleEscapeKey);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [handleClose]);

  return (
    <div className='panel'>
      <Button iconOnly='Close' use='subtle' label='close' onClick={handleClose} />
      <Outlet />
    </div>
  );
}

export default PanelLayout;
