import SidebarSettings from "Compositions/SidebarSettings/SidebarSettings";
import React from "react";
import { Outlet } from "react-router-dom";

function SettingsLayout() {
  return (
    <div className='settings-wrapper'>
      <SidebarSettings />
      <Outlet />
    </div>
  );
}

export default SettingsLayout;
