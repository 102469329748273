import { Tag } from "Components/index";
import React from "react";

function CellTag({ contents }) {
  const tags = Array.isArray(contents) ? contents : [contents];

  return (
    <>
      {tags.map((tag) => {
        return <Tag key={tag} id={tag} />;
      })}
    </>
  );
}

export default CellTag;
