import PageHeader from "Compositions/PageHeader/PageHeader";
import HistoryRow from "./HistoryRow";
import { useGetRows } from "Hooks/useGetRows";

function History() {
  const {
    data: ticketData,
    isLoading: ticketLoading,
    error: ticketError,
  } = useGetRows({ table: "tickets" });

  if (ticketLoading) {
    return <p>Loading...</p>;
  }

  if (ticketError) {
    return <p>Error: {ticketError.message}</p>;
  }
  if (ticketData.length === 0) {
    return <p>No data</p>;
  }
  return (
    <div>
      <PageHeader title='History' icon='History' subtitle='subtitle' />
      <table>
        <thead>
          <HistoryRow type='header' />
        </thead>
        <tbody>
          {ticketData.map((ticket) => (
            <HistoryRow key={ticket.id} row={ticket} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default History;
