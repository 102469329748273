import React, { useState, useEffect } from "react";
import { Button } from "Components/";

function SidebarToggle() {
  const [isOpen, setIsOpen] = useState(window.innerWidth >= 1800);
  const [iconName, setIconName] = useState(window.innerWidth < 112 ? "Menu" : "Sidebar");

  useEffect(() => {
    const checkWindowSize = () => {
      if (window.innerWidth < 800) {
        setIsOpen(false);
        setIconName("Menu");
      } else {
        setIsOpen(true);
        if (window.innerWidth < 512) {
          setIconName("Menu");
        } else {
          setIconName("Sidebar");
        }
      }
    };

    // Run the check initially and on every resize event
    checkWindowSize();
    window.addEventListener("resize", checkWindowSize);

    return () => window.removeEventListener("resize", checkWindowSize);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.setAttribute("data-sidebar-state", "open");
    } else {
      document.body.setAttribute("data-sidebar-state", "closed");
    }
  }, [isOpen]);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Button
      use='text'
      label={isOpen ? "Close sidebar" : "Open sidebar"}
      size='small'
      iconOnly={iconName}
      onClick={handleButtonClick}
    />
  );
}

export default SidebarToggle;
