import PageHeader from "Compositions/PageHeader/PageHeader";

import { useGetRows } from "Hooks/useGetRows";
import { QueueRow } from "..";

function Queue() {
  const {
    data: ticketData,
    isLoading: ticketLoading,
    error: ticketError,
  } = useGetRows({ table: "tickets" });

  if (ticketLoading) {
    return <p>Loading...</p>;
  }

  if (ticketError) {
    return <p>Error: {ticketError.message}</p>;
  }
  if (ticketData.length === 0) {
    return <p>No data</p>;
  }
  return (
    <div>
      <PageHeader
        title='Ticket queue'
        icon='Queue'
        subtitle={"What's being worked on right now?"}
      />
      <table>
        <thead>
          <QueueRow type='header' />
        </thead>
        <tbody>
          {ticketData.map((ticket) => (
            <QueueRow key={ticket.id} row={ticket} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Queue;
