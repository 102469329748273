import PageHeader from "Compositions/PageHeader/PageHeader";

function Customers() {
  return (
    <div>
      <PageHeader title='Customers' icon='Customers' subtitle='subtitle' />
    </div>
  );
}

export default Customers;
