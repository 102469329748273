import { Icon } from "Components/index";

function CellEllipsis() {
  return (
    <>
      <Icon icon='EllipsisVertical' />
    </>
  );
}

export default CellEllipsis;
