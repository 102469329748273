import { TableCell } from "Components/index";

function TeamRow({ type, row }) {
  switch (type) {
    case "header":
      return (
        <tr>
          <TableCell type='header' contents='User' />
          <TableCell type='header' contents='Role' />
          <TableCell type='header' contents='Skills' />
          <TableCell type='header' contents='Level' />
          <TableCell type='header' contents='' />
        </tr>
      );
    default:
      return (
        <tr>
          <TableCell link={`/settings/team/${row.slug}`} type='user-profile' contents={row.id} />
          <TableCell link={`/settings/team/${row.slug}`} type='text' contents={row.role} />
          <TableCell link={`/settings/team/${row.slug}`} type='tag' contents={row.skills} />
          <TableCell link={`/settings/team/${row.slug}`} type='tag' contents={row.level} />
          <TableCell type='ellipsis' contents='hello' />
        </tr>
      );
  }
}
export default TeamRow;
