import React from "react";
// import { BlockNoteEditor } from "@blocknote/core";
import { slashMenuItems } from "Components/SlashMenuItems/SlashMenuItems";
import {
  BlockNoteView,
  FormattingToolbarPositioner,
  HyperlinkToolbarPositioner,
  SlashMenuPositioner,
  ToggledStyleButton,
  darkDefaultTheme,
  Toolbar,
  //ToolbarButton,
  useBlockNote,
  // useEditorContentChange,
  useEditorSelectionChange,
} from "@blocknote/react";
import "@blocknote/core/style.css";

const CustomFormattingToolbar = (props) => {
  // Updates state on selection change.
  useEditorSelectionChange(props.editor, () => {});

  return (
    <Toolbar>
      {/* Default button to toggle bold. */}
      <ToggledStyleButton editor={props.editor} toggledStyle={"bold"} />
      {/* Default button to toggle italic. */}
      <ToggledStyleButton editor={props.editor} toggledStyle={"italic"} />
      {/* Default button to toggle underline. */}
      <ToggledStyleButton editor={props.editor} toggledStyle={"underline"} />
      {/* Custom button to toggle blue text & background color. */}
    </Toolbar>
  );
};

const theme = {
  ...darkDefaultTheme,
  componentStyles: (theme) => ({
    // Adds basic styling to the editor.
    Editor: {
      backgroundColor: "unset",
      borderRadius: "unset",
      border: "unset",
      boxShadow: "unset",
    },
    // Makes all hovered dropdown & menu items blue.
    Toolbar: {
      ".mantine-Menu-dropdown": {
        ".mantine-Menu-item:hover": {
          backgroundColor: "unset",
        },
      },
    },
  }),
};

export default function Editor({ variant }) {
  // Creates a new editor instance.
  const editor = useBlockNote({
    domAttributes: {
      // Adds a class to all `blockContainer` elements.
      blockContainer: {
        class: "block-container",
      },
    },
    slashMenuItems,
  });

  // Renders the editor instance.
  return (
    <div className='editor-container' data-variant={variant}>
      <BlockNoteView editor={editor} theme={theme}>
        <FormattingToolbarPositioner editor={editor} formattingToolbar={CustomFormattingToolbar} />
        <HyperlinkToolbarPositioner editor={editor} />
        <SlashMenuPositioner editor={editor} />
      </BlockNoteView>
    </div>
  );
}
