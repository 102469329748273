import { Avatar, Button } from "Components/index";
import React from "react";

function Integration({ contents }) {
  return (
    <div className={`integration ${contents.slug}`}>
      <header className='integration-header'>
        <Avatar size='medium' url={contents.logo} Style='rounded' />
        <Button use='outline' size='xSmall' label='Connect' />
      </header>
      <div className='integration-details'>
        <h3>{contents.name}</h3>
        <p>{contents.description}</p>
      </div>
    </div>
  );
}

export default Integration;
