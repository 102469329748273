import React from "react";
import Input from "../Items/Input/Input";
import FormToggle from "../Items/Toggle/FormToggle";
import Range from "../Items/Range/Range";

function FormItem({
  itemType,
  children,
  name,
  value,
  toggleType,
  inputType,
  label,
  onChange,
  helperSecondary,
  helperPrimary,
  options,
  placeholder,
  autocomplete,
  readOnly,
  disabled,
  min,
  max,
  step,
  hidden,
}) {
  switch (itemType) {
    case "toggle":
      return (
        <div className='form-item' data-form-item-type={itemType} data-hidden={hidden}>
          <label htmlFor={name}>
            <FormToggle
              toggleType={toggleType}
              name={name}
              value={value}
              onChange={onChange}
              label={label}
            />
          </label>
          <div className='helpers'>
            {helperPrimary && <span className='helper-primary'>{helperPrimary}</span>}
            {helperSecondary && <span className='helper-secondary'>{helperSecondary}</span>}
          </div>
          {children}
        </div>
      );
    case "range": {
      return (
        <Range
          {...{
            name,
            value,
            onChange,
            label,
            helperSecondary,
            helperPrimary,
            options,
            placeholder,
            autocomplete,
            readOnly,
            disabled,
            min,
            max,
            step,
          }}
        />
      );
    }
    case "fileUpload": {
      return <p>File upload</p>;
    }
    default:
      return (
        <div className='form-item' data-form-item-type={itemType} data-hidden={hidden}>
          {label ? <label htmlFor={name}>{label}</label> : null}
          <Input
            inputType={inputType}
            name={name}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            options={options}
            onChange={onChange}
            autocomplete={autocomplete}
            readOnly={readOnly}
          />
          {helperSecondary && <span className='helper-secondary'>{helperSecondary}</span>}
          {helperPrimary && <span className='helper-primary'>{helperPrimary}</span>}
          {children}
        </div>
      );
  }
}

export default FormItem;
