import PageHeader from "Compositions/PageHeader/PageHeader";

function CustomerData() {
  return (
    <div className='settings-page'>
      <PageHeader title='Customer data' icon='CustomerData' subtitle='subtitle' />
    </div>
  );
}

export default CustomerData;
