import { useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "Config/supabase";

export function useCreateRow(table) {
  const queryClient = useQueryClient();

  return useMutation(
    async (newData) => {
      const { data, error } = await supabase.from(table).insert(newData).select();
      if (error) throw error;
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([table]);
      },
    }
  );
}
