import { useGetRows } from "Hooks/useGetRows";
import { SettingsSection } from "Layouts/index";
import BillingInvoicesRow from "./BillingInvoicesRow";

function BillingInvoices() {
  const { data, isLoading, error } = useGetRows({ table: "invoices" });

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <SettingsSection title='Invoices' settingsType='invoices'>
      <div className='settings-table-narrow'>
        <table data-table-style='rounded'>
          <thead>
            <BillingInvoicesRow type='header' />
          </thead>
          <tbody>
            {data.map((row) => (
              <BillingInvoicesRow key={row.id} row={row} />
            ))}
          </tbody>
        </table>
      </div>
    </SettingsSection>
  );
}

export default BillingInvoices;
