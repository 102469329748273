import { FormItem } from "Components/index";
import React, { useState } from "react";

function Range({ label, name, helper, onChange, value = 0, step = 1, max = 100 }) {
  const [rangeValue, setRangeValue] = useState(value);
  const maxValue = max;

  const getBackgroundSize = () => {
    return {
      backgroundSize: `${(rangeValue * 100) / maxValue}% 100%`,
    };
  };

  const onRangeChange = (event) => {
    setRangeValue(event.target.value);
    onChange(event);
  };

  const onInputChange = (event) => {
    setRangeValue(event.target.value);
    onChange(event);
  };

  return (
    <div className='range-container'>
      {label && <label>{label}</label>}
      {helper && <p className='helper'>{helper}</p>}
      <div className='range-slider'>
        <input
          name={name}
          type='range'
          min='0'
          step={step}
          max={maxValue}
          style={getBackgroundSize()}
          onChange={onRangeChange}
          value={rangeValue}
        />
        <FormItem
          name={name}
          itemType='input'
          inputType='number'
          min='0'
          max={maxValue}
          value={rangeValue}
          onChange={onInputChange}
        />
        <p>hours</p>
      </div>
    </div>
  );
}

export default Range;
