export const actionTypes = {
  SET_MESSAGES: "SET_MESSAGES",
  SET_TAGS: "SET_TAGS",
};

export function MessageTagReducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_MESSAGES:
    case actionTypes.SET_TAGS:
      const newItems = [...state.items, ...action.payload];
      const uniqueItems = newItems.filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) => t.id === item.id && t.timelineItemType === item.timelineItemType)
      );
      const sortedItems = uniqueItems.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );

      let tempTags = [];
      const mergedItems = [];

      sortedItems.forEach((item, index) => {
        if (item.timelineItemType === "tag") {
          tempTags.push(item);
        } else {
          if (tempTags.length > 0) {
            mergedItems.push({
              tags: tempTags,
              timelineItemType: "tag",
              created_at: tempTags[0].created_at,
            });
            tempTags = [];
          }
          mergedItems.push(item);
        }

        if (index === sortedItems.length - 1 && tempTags.length > 0) {
          mergedItems.push({
            tags: tempTags,
            timelineItemType: "tag",
            created_at: tempTags[0].created_at,
          });
        }
      });

      return { ...state, items: mergedItems };

    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
}
