import { useGetRow } from "Hooks/";

export default function FetchUsersName(id) {
  const { data, isLoading, error } = useGetRow("user_profiles", id);

  if (isLoading || error) {
    return null;
  }
  return `${data.first_name} ${data.last_name}`;
}
