import React, { useState } from "react";
import { Button, Avatar } from "..";

function FileUpload({
  imageUrl,
  name,
  initials,
  profileColor,
  imageSize,
  label,
  imageStyle,
  handleFileUpload,
  clearFile,
  helperSecondary,
  altText,
}) {
  const [isUploading, setUploading] = useState(false);
  const inputRef = React.useRef(null);

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    const name = event.target.name; // Already retrieved the name here
    setUploading(true);
    await handleFileUpload(name, file); // Passing both name and file
    inputRef.current.value = ""; // Clear the file input
    setUploading(false);
  };

  return (
    <div className='form-item form-item-file-upload'>
      <label htmlFor={name}>{label}</label>
      <div className='form-item-contents'>
        {imageUrl || initials ? (
          <Avatar
            url={imageUrl}
            name={altText}
            style={imageStyle}
            initials={initials || null}
            size={imageSize || "xxLarge"}
            profileColor={profileColor || null}
          />
        ) : null}

        <div className='file-upload-actions'>
          <div className='file-upload-buttons'>
            <Button
              label={isUploading ? "Uploading" : "Upload photo"}
              iconLead='Upload'
              onClick={() => inputRef.current.click()}
            />
            {imageUrl && <Button use='outline' label='clear' onClick={() => clearFile(name)} />}
            <input
              ref={inputRef}
              type='file'
              id={name}
              name={name}
              onChange={onFileChange}
              accept='image/png, image/jpeg'
            />
          </div>
          <p className='helper-secondary'>{helperSecondary}</p>
        </div>
      </div>
    </div>
  );
}

export default FileUpload;
