import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormBlock, FormRow, FormItem } from 'Components/';
import { Button } from 'Components/index';
import { supabase } from "Config/supabase";

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [error, setError] = useState(null);
  const [step, setStep] = useState(0);

  const navigate = useNavigate();

  const handleSignUp = async (event) => {
    event.preventDefault(); // Prevent the form from reloading

    try {
      if (step === 0) {
        setStep(1);
        return;
      }

      // You might want to integrate storing the other details like firstName, lastName, etc.
      let { error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            firstName,
            lastName,
            companyName,
          },
        },
      });

      if (error) throw error;

      navigate("/signup/verify");
    } catch (error) {
      setError(error.error_description || error.message);
    }
  };

  return (
    <div className='auth-page-form'>
      <header>
        <h1>Signup</h1>
        <p>Welcome to Fixate!</p>
      </header>
      <div className='form'>
        <form onSubmit={handleSignUp}>
          {step === 0 ? (
            // First form
            <FormBlock>
              <FormRow>
                <FormItem
                  label='First Name'
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  itemType='input'
                />
                <FormItem
                  label='Last Name'
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  itemType='input'
                />
              </FormRow>
              <FormRow>
                <FormItem
                  label='Email address'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  itemType='input'
                  inputType='email'
                />
              </FormRow>
              <FormRow>
                <FormItem
                  label='Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  itemType='input'
                  inputType='password'
                />
              </FormRow>
            </FormBlock>
          ) : (
            // Second form
            <FormBlock>
              <FormRow>
                <FormItem
                  label='Company Name'
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  itemType='input'
                />
              </FormRow>
            </FormBlock>
          )}
          {error && <p>{error}</p>}
          <Button label={step === 0 ? "Next" : "Sign up"} onClick={handleSignUp} />
        </form>
      </div>
    </div>
  );
}

export default Signup;
