import { Icon } from "Components/index";

import {
  default as ReactSelect,
  components,
  DropdownIndicatorProps,
  ClearIndicatorProps,
} from "react-select";

const commonProps = {
  classNamePrefix: "react-select",
  isSearchable: true,
  isClearable: true,
  isMulti: false,
  unstyled: true,
  // menuIsOpen: true,
};

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon='ChevronDown' />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon icon='Close' size='small' />
    </components.ClearIndicator>
  );
};

function Select({ options, value, onChange, placeholder, name, showClear, isDisabled }) {
  return (
    <ReactSelect
      {...commonProps}
      value={value}
      name={name}
      options={options}
      isClearable={showClear}
      isDisabled={isDisabled}
      onChange={onChange}
      placeholder={placeholder}
      components={{ DropdownIndicator, ClearIndicator }}
    />
  );
}

export default Select;
