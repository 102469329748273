import FetchEndUserName from "Components/FetchData/FetchEndUserName";
import { TableCell } from "Components/index";

function HistoryRow({ type, row }) {
  switch (type) {
    case "header":
      return (
        <tr>
          <TableCell type='header' contents='User' />
          <TableCell type='header' contents='Ticket' />
          <TableCell type='header' contents='Activity' />
          <TableCell type='header' contents='' />
        </tr>
      );
    default:
      return (
        <>
          <tr>
            <TableCell
              link={`/tickets/${row.slug}`}
              type='text'
              contents={<FetchEndUserName id={row.end_user} />}
            />
            <TableCell link={`/tickets/${row.slug}`} type='ticket' contents={row.id} />
            <TableCell
              link={`/tickets/${row.slug}`}
              type='ticket-history-activity'
              contents={row}
            />
            <TableCell type='ellipsis' contents='hello' />
          </tr>
        </>
      );
  }
}

export default HistoryRow;
