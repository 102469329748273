import { useQuery } from "@tanstack/react-query";
import { supabase } from "Config/supabase";
import { useCurrentUser } from "Hooks/";

export function useGetRowsPublic({
  table,
  sortColumn,
  sortAscending = false,
  successCallback,
  filter,
}) {
  const { organization } = useCurrentUser();
  const orgId = organization;

  return useQuery({
    queryKey: [table],
    queryFn: async () => {
      let query = supabase.from(table).select("*");
      if (filter) {
        query = query.filter((row) => filter(row));
      }

      if (sortColumn) {
        query = query.order(sortColumn, { ascending: sortAscending });
      }
      const { data, error } = await query; // Just await the query

      if (error) throw error;
      if (successCallback) successCallback(data);
      return data;
    },
    staleTime: 10 * (60 * 1000), // 10 minutes
    enabled: !!orgId,
  });
}
