import { useState, useEffect } from "react";
import { useGetRow, useUpdateRow } from "Hooks/";
import { supabase, supabaseUrl } from "Config/supabase";

export function useForm(table, id, initialState) {
  const [formState, setFormState] = useState(initialState);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [statusText, setStatusText] = useState(null);

  const { data, isLoading, error } = useGetRow(table, id, (data) => {
    setFormState({ ...data });
  });

  const discardChanges = () => {
    setFormState({ ...data });
    setUnsavedChanges(false);
  };

  const updateRowMutation = useUpdateRow(table, id, {
    onSuccess: () => {
      setStatusText("Saved");
      setTimeout(() => setStatusText(null), 2000);
      setUnsavedChanges(false);
    },
    onError: () => {
      setStatusText("Error while saving");
    },
  });

  const handleFormTextChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });

    setUnsavedChanges(true);
    setStatusText("Unsaved changes");
  };

  const handleFormSelectChange = (selectedOption, { name }) => {
    setFormState({
      ...formState,
      [name]: selectedOption ? selectedOption.value : null, // This will allow you to handle the case where no option is selected
    });

    setUnsavedChanges(true);
    setStatusText("Unsaved changes");
  };
  const clearImage = (name) => {
    setFormState({
      ...formState,
      [name]: null,
    });
    setUnsavedChanges(true); // Mark that there are unsaved changes
    setStatusText("Unsaved changes"); // Update the status text
  };

  const handleFileUpload = async (name, file) => {
    const { data, error } = await supabase.storage
      .from("public")
      .upload(`avatars/${id}-${Date.now()}`, file, {
        cacheControl: "3600",
        upsert: true,
      });
    if (error) {
      console.log("HELLO THERE", error);
      return;
    }
    setFormState({
      ...formState,
      [name]: `${supabaseUrl}/storage/v1/object/public/public/${data.path}`,
    });
    setUnsavedChanges(true);
    setStatusText("Unsaved changes");
  };

  const handleFormToggleChange = (event) => {
    const newValue = event.target.checked ? true : false;
    setFormState({
      ...formState,
      [event.target.name]: newValue,
    });

    setUnsavedChanges(true);
    setStatusText("Unsaved changes");
  };

  const saveChanges = () => {
    setStatusText("Saving...");
    updateRowMutation.mutate(formState, {
      onSuccess: () => {
        setStatusText("Saved");
        setTimeout(() => setStatusText(null), 2000);
        setUnsavedChanges(false);
      },
    });
  };

  useEffect(() => {
    if (data) {
      setFormState({ ...data });
    }
  }, [data]);

  return {
    formState,
    setFormState,
    unsavedChanges,
    setUnsavedChanges,
    statusText,
    setStatusText,
    isLoading,
    error,
    discardChanges,
    clearImage,
    handleFormTextChange,
    handleFormToggleChange,
    saveChanges,
    handleFileUpload,
    handleFormSelectChange,
  };
}
