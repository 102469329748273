import { useQuery } from "@tanstack/react-query";
import { supabase } from "Config/supabase";
import { useCurrentUser } from "Hooks/";

export function useGetSlugRow(table, slug = null, successCallback) {
  const { organization } = useCurrentUser();
  const orgId = organization;

  return useQuery({
    staleTime: 1 * (60 * 1000),
    enabled: !!slug && !!orgId,
    queryKey: [table, slug],
    queryFn: async () => {
      let query = supabase.from(table).select("*").eq("slug", slug);

      if (table !== "organizations" && table !== "tag_categories") {
        query = query.eq("organization", orgId);
      }

      const { data, error } = await query.single();

      if (error) throw error;
      if (successCallback) successCallback(data);

      return data;
    },
  });
}
