import ScrollComponent from "./ScrollMenu";
import SidebarMenuItem from "./SidebarMenuItem";

function SidebarMenu({ navItems, name, location }) {
  return (
    <ScrollComponent>
      <nav className='sidebar-menu' data-menu-location={location} data-menu-name={name}>
        <ul>
          {navItems.map((navItem) => (
            <SidebarMenuItem key={navItem.id} navItem={navItem} />
          ))}
        </ul>
      </nav>
    </ScrollComponent>
  );
}

export default SidebarMenu;
