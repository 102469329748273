import { Link, Outlet } from "react-router-dom";
import { supabase } from "Config/supabase";
import { useEffect, useState } from "react";
import { Logo } from "Components/index";
import { SidebarPrimary } from "Compositions/index";

function AppLayout() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
  }, []);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (!session) {
    return (
      <div className='auth'>
        <div className='auth-container'>
          <Logo />
          <div>
            You are logged out. <Link to='/login'> Login.</Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='fixate-app'>
      <SidebarPrimary />
      <main>
        <Outlet />
      </main>
    </div>
  );
}

export default AppLayout;
