import Toggle from "./Toggle";

function FormToggle({ toggleType, name, value, onChange, label }) {
  const handleClick = () => {
    onChange({ target: { name, value: !value, checked: !value } }); // toggle the value
  };

  return (
    <div className='form-toggle' data-toggle-type={toggleType}>
      <Toggle value={value} handleClick={handleClick} name={name} />
      <label onClick={handleClick} htmlFor={name}>
        {label}
      </label>
    </div>
  );
}

export default FormToggle;

// When to use toggles:
// - Switch: enabling/disabling a single Settings
// - Checkbox: enabling/disabling multiple Settings
// - Radio: selecting a single option from a list of options
