import { useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "Config/supabase";

export function useUpdateRow(table, id) {
  const queryClient = useQueryClient();

  return useMutation(
    async (newData) => {
      const { data, error } = await supabase.from(table).update(newData).eq("id", id);
      if (error) throw error;
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([table]);
        queryClient.invalidateQueries([table, id]);
      },
    }
  );
}
