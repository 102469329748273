import React, { useEffect, useRef } from "react";

function Popover({ children, location, setIsVisible, isVisible, buttonRef }) {
  const popoverRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isVisible &&
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsVisible, isVisible, buttonRef]);

  const handleButtonClick = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      {isVisible && (
        <div
          className='popover'
          data-popover-location={location}
          ref={popoverRef}
          onClick={handleButtonClick}
        >
          {children}
        </div>
      )}
    </>
  );
}

export default Popover;
