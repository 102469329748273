import Icon from "Components/Icon/Icon";
import { NavLink } from "react-router-dom";

function SidebarMenuItem({ navItem }) {
  const { link, icon, label } = navItem;
  return (
    <li>
      <NavLink className='sidebar-nav-item' to={link}>
        {icon ? <Icon icon={icon} /> : null}
        <span className='label'>{label}</span>
      </NavLink>
    </li>
  );
}

export default SidebarMenuItem;
