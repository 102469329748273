export const dayTimeOptions = [];

for (let i = 0; i < 24; i++) {
  for (let j = 0; j < 60; j += 15) {
    let hour = i < 10 ? `0${i}` : i;
    let minute = j < 10 ? `0${j}` : j;
    let ampm = i < 12 || i === 0 ? "AM" : "PM";
    if (i === 0) hour = 12;
    if (i > 12) hour -= 12;
    dayTimeOptions.push({
      value: `${ampm}${i < 10 ? `0${i}` : i}${minute}`, // AM/PM included in value
      label: `${hour}:${minute}${ampm}`,
    });
  }
}
