import { Tooltip as ReactTooltip } from "react-tooltip";

function Tooltip({ label, place = "top", attachTo, children }) {
  return (
    <ReactTooltip
      anchorSelect={`[data-tooltip-id=${attachTo}]`}
      place={place}
      disableStyleInjection={true}
      delayHide={250}
    >
      {label}
      {children}
    </ReactTooltip>
  );
}

export default Tooltip;
