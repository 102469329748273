import SidebarMenu from "Components/SidebarMenu/SidebarMenu";
import PageHeader from "Compositions/PageHeader/PageHeader";
import { NavItemsSettings } from "Config/navItems";

function SidebarSettings() {
  return (
    <div className='sidebar sidebar-settings' data-theme='dark'>
      <PageHeader title='Settings' icon='Settings' />
      <SidebarMenu location='sidebar-settings' name='settings' navItems={NavItemsSettings} />
    </div>
  );
}

export default SidebarSettings;
