import React from "react";

function Notifications() {
  return (
    <>
      <path
        d='M5.75 16.5H18.25V15H5.75V16.5ZM18.5 16.75V18.25H20V16.75H18.5ZM18.25 18.5H5.75V20H18.25V18.5ZM5.5 18.25V16.75H4V18.25H5.5ZM5.75 18.5C5.61193 18.5 5.5 18.3881 5.5 18.25H4C4 19.2165 4.7835 20 5.75 20V18.5ZM18.5 18.25C18.5 18.3881 18.3881 18.5 18.25 18.5V20C19.2165 20 20 19.2165 20 18.25H18.5ZM18.25 16.5C18.3881 16.5 18.5 16.6119 18.5 16.75H20C20 15.7835 19.2165 15 18.25 15V16.5ZM5.75 15C4.7835 15 4 15.7835 4 16.75H5.5C5.5 16.6119 5.61193 16.5 5.75 16.5V15Z'
        fill='currentColor'
      ></path>
      <path
        d='M17.5 15.25C17.5 15.6642 17.8358 16 18.25 16C18.6642 16 19 15.6642 19 15.25H17.5ZM5 15.25C5 15.6642 5.33579 16 5.75 16C6.16421 16 6.5 15.6642 6.5 15.25H5ZM17.5 13V15.25H19V13H17.5ZM6.5 15.25V13H5V15.25H6.5ZM12 7.5C15.0376 7.5 17.5 9.96243 17.5 13H19C19 9.13401 15.866 6 12 6V7.5ZM12 6C8.13401 6 5 9.13401 5 13H6.5C6.5 9.96243 8.96243 7.5 12 7.5V6Z'
        fill='currentColor'
      ></path>
      <path
        d='M12 5V6C12.5523 6 13 5.55228 13 5H12ZM12 5H11C11 5.55228 11.4477 6 12 6V5ZM12 5V4C11.4477 4 11 4.44772 11 5H12ZM12 5H13C13 4.44772 12.5523 4 12 4V5Z'
        fill='currentColor'
      ></path>
    </>
  );
}

export default Notifications;
