import Integration from "Compositions/Integration/Integration";
import PageHeader from "Compositions/PageHeader/PageHeader";
import { useGetRowsPublic } from "Hooks/";
import { SettingsSection } from "Layouts/index";

function Integrations() {
  const { data, isLoading, error } = useGetRowsPublic({ table: "integrations" });

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const categorizedData = data.reduce((acc, integration) => {
    (acc[integration.category] = acc[integration.category] || []).push(integration);
    return acc;
  }, {});

  const sortedCategories = [
    "Notifications",
    "CRM",
    "Issue Tracking",
    "Communication",
    "Automation",
    "Meetings",
  ];

  return (
    <div className='settings-page'>
      <PageHeader
        title='Integrations'
        guideUrl='https://fixatehq.com'
        icon='Integrations'
        subtitle='subtitle'
      />
      {sortedCategories.map((category) => (
        <SettingsSection title={category} key={category} settingsType='integration'>
          <div className='integrations'>
            {categorizedData[category]?.map((integration) => (
              <Integration key={integration.id} contents={integration} />
            ))}
          </div>
        </SettingsSection>
      ))}
    </div>
  );
}

export default Integrations;
