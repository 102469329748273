import React, { useState, useEffect, useRef } from "react";

const ScrollComponent = ({ children }) => {
  const containerRef = useRef();
  const [showButtons, setShowButtons] = useState(false);

  const checkSize = () => {
    if (containerRef.current) {
      const show = containerRef.current.offsetWidth < containerRef.current.scrollWidth;
      setShowButtons(show);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", checkSize);
    checkSize();

    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, []);

  const scrollContent = (direction) => {
    const scrollAmount = 200;
    const container = containerRef.current;

    if (direction === "right") {
      container.scrollLeft += scrollAmount;
    } else if (direction === "left") {
      container.scrollLeft -= scrollAmount;
    }
  };

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      {showButtons && (
        <button onClick={() => scrollContent("left")} style={{ position: "absolute", left: 0 }}>
          Left
        </button>
      )}

      <div ref={containerRef} style={{ overflowX: "hidden", whiteSpace: "nowrap" }}>
        {children}
      </div>

      {showButtons && (
        <button onClick={() => scrollContent("right")} style={{ position: "absolute", right: 0 }}>
          Right
        </button>
      )}
    </div>
  );
};

export default ScrollComponent;
