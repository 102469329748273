import { useQuery } from "@tanstack/react-query";
import { supabase } from "Config/supabase";

export function useGetRowGlobal(table, id, successCallback) {
  return useQuery({
    enabled: !!id,
    staleTime: 100 * (60 * 1000),
    queryKey: [table, id],
    queryFn: async () => {
      let query = supabase.from(table).select("*").eq("id", id);

      const { data, error } = await query.single();

      if (error) throw error;
      if (successCallback) successCallback(data);
      return data;
    },
  });
}
