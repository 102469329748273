import { useForm, useCurrentUser } from "Hooks/";
import { FormBlock } from "Components/";
import { PageHeader } from "Compositions/";
import { SettingsSection } from "Layouts/";
import SettingsFooter from "Compositions/SettingsFooter/SettingsFooter";
import NotificationItem from "./NotificationItem";
import { notificationOptions } from "Data/notificationOptions";

function Notifications() {
  const { id } = useCurrentUser();
  const table = "user_notifications";
  const {
    formState,
    unsavedChanges,
    statusText,
    isLoading,
    error,
    handleFormToggleChange,
    handleFormSelectChange,
    saveChanges,
  } = useForm(table, id, {});

  if (isLoading === undefined) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  return (
    <div className='settings-page'>
      <PageHeader
        title='Policies'
        guideUrl='https://fixatehq.com'
        icon='Policies'
        subtitle='subtitle'
      />
      <SettingsSection settingsType='notifications' title='Ticket notifications'>
        <FormBlock>
          <NotificationItem
            label='Your stranded tickets'
            enabledName={"your_stranded_tickets_enabled"}
            description='Get a notification when there are tickets you have the skills or level to answer'
            enabled={formState.your_stranded_tickets_enabled}
            enabledOnChange={handleFormToggleChange}
            notificationOptions={notificationOptions}
            notificationValue={notificationOptions.find(
              (option) => option.value === formState.your_stranded_tickets_notification
            )}
            notificationOnChange={handleFormSelectChange}
            notificationName={"your_stranded_tickets_notification"}
          />
          <NotificationItem
            label='All stranded tickets'
            enabledName={"all_stranded_tickets_enabled"}
            description='Get a notification when there are tickets where there is no one online to answer'
            enabled={formState.all_stranded_tickets_enabled}
            enabledOnChange={handleFormToggleChange}
            notificationOptions={notificationOptions}
            notificationValue={notificationOptions.find(
              (option) => option.value === formState.all_stranded_tickets_notification
            )}
            notificationOnChange={handleFormSelectChange}
            notificationName={"all_stranded_tickets_notification"}
          />
          <NotificationItem
            label='Max response time'
            enabledName={"max_response_time_enabled"}
            description='Get a notification when there are tickets approaching their max response time'
            enabled={formState.max_response_time_enabled}
            enabledOnChange={handleFormToggleChange}
            notificationOptions={notificationOptions}
            notificationValue={notificationOptions.find(
              (option) => option.value === formState.max_response_time_notification
            )}
            notificationOnChange={handleFormSelectChange}
            notificationName={"max_response_time_notification"}
          />
        </FormBlock>
      </SettingsSection>
      <SettingsSection settingsType='notifications' title='Marketing emails'>
        <FormBlock>
          <NotificationItem
            label='New features'
            enabledName={"marketing_new_features_enabled"}
            description='Receive an email with new features and updates'
            enabled={formState.marketing_new_features_enabled}
            enabledOnChange={handleFormToggleChange}
            notificationOptions={notificationOptions}
            notificationValue={notificationOptions.find(
              (option) => option.value === formState.marketing_new_features_notification
            )}
            notificationOnChange={handleFormSelectChange}
            notificationName={"marketing_new_features_notification"}
          />
          <NotificationItem
            label='Other marketing emails'
            enabledName={"marketing_other_enabled"}
            description='Receive an email with other updates from Hark'
            enabled={formState.marketing_other_enabled}
            enabledOnChange={handleFormToggleChange}
            notificationOptions={notificationOptions}
            notificationValue={notificationOptions.find(
              (option) => option.value === formState.marketing_other_notification
            )}
            notificationOnChange={handleFormSelectChange}
            notificationName={"marketing_other_notification"}
          />
        </FormBlock>
      </SettingsSection>
      <SettingsFooter
        unsavedChanges={unsavedChanges}
        saveChanges={saveChanges}
        unsavedChangesText={statusText}
      />
    </div>
  );
}

export default Notifications;
