import React from "react";

function Incoming() {
  return (
    <>
      <path
        fill='currentColor'
        d='M12.75 4.75a.75.75 0 0 0-1.5 0h1.5Zm-.75 5.5-.557.502a.75.75 0 0 0 1.114 0L12 10.25Zm-1.693-3.002a.75.75 0 0 0-1.114 1.004l1.114-1.004Zm4.5 1.004a.75.75 0 1 0-1.114-1.004l1.114 1.004ZM11.25 4.75v5.5h1.5v-5.5h-1.5Zm1.307 4.998-2.25-2.5-1.114 1.004 2.25 2.5 1.114-1.004Zm0 1.004 2.25-2.5-1.114-1.004-2.25 2.5 1.114 1.004ZM9.75 13.75h.75a.75.75 0 0 0-.75-.75v.75Zm4.5 0V13a.75.75 0 0 0-.75.75h.75ZM7.187 6.57a.75.75 0 0 0-.374-1.452l.374 1.453Zm10-1.452a.75.75 0 1 0-.374 1.453l.374-1.453ZM16.25 18.5h-8.5V20h8.5v-1.5ZM5.5 16.25v-2.5H4v2.5h1.5Zm0-2.5v-5H4v5h1.5Zm-.75.75h5V13h-5v1.5ZM9 13.75v.5h1.5v-.5H9ZM11.75 17h.5v-1.5h-.5V17ZM15 14.25v-.5h-1.5v.5H15Zm5 2v-2.5h-1.5v2.5H20Zm-5.75-1.75h5V13h-5v1.5Zm5.75-.75v-5h-1.5v5H20ZM12.25 17A2.75 2.75 0 0 0 15 14.25h-1.5c0 .69-.56 1.25-1.25 1.25V17Zm-4.5 1.5a2.25 2.25 0 0 1-2.25-2.25H4A3.75 3.75 0 0 0 7.75 20v-1.5ZM9 14.25A2.75 2.75 0 0 0 11.75 17v-1.5c-.69 0-1.25-.56-1.25-1.25H9ZM16.25 20A3.75 3.75 0 0 0 20 16.25h-1.5a2.25 2.25 0 0 1-2.25 2.25V20ZM5.5 8.75c0-1.047.716-1.93 1.687-2.18l-.374-1.452A3.751 3.751 0 0 0 4 8.75h1.5Zm14.5 0a3.751 3.751 0 0 0-2.813-3.632l-.374 1.453A2.251 2.251 0 0 1 18.5 8.75H20Z'
      ></path>
    </>
  );
}

export default Incoming;
