import React from "react";

function SettingsSection({ children, title, subtitle, settingsType }) {
  return (
    <section className='settings-section' data-settings-type={settingsType}>
      {title ? (
        <header className='settings-section-header'>
          <h3>{title}</h3>
          {subtitle ? <p>{subtitle}</p> : null}
        </header>
      ) : null}
      {children}
    </section>
  );
}

export default SettingsSection;
