import React from "react";

function CellReplyDetails({ contents }) {
  return (
    <>
      <span className='title'>{contents.title}</span>
      <span className='excerpt'>{contents.content}</span>
    </>
  );
}

export default CellReplyDetails;
