import PageHeader from "Compositions/PageHeader/PageHeader";

function Reports() {
  return (
    <div>
      <PageHeader title='Reports' icon='Reports' subtitle='subtitle' />
    </div>
  );
}

export default Reports;
