import React from "react";
import Icon from "Components/Icon/Icon";
import { Link } from "react-router-dom";

function Button(props, ref) {
  const {
    use = "primary",
    label,
    url,
    iconLead,
    iconTrail,
    iconOnly,
    size = "medium",
    disabled,
    loading,
    onClick,
    popoverId,
    isSelected,
    removePadding,
  } = props;

  const handleClick = (e) => {
    if (ref && ref.current) {
      ref.current.blur(); // make the button lose focus
    }

    if (onClick) {
      onClick(e); // run the onClick action
    }
  };

  const buttonProps = {
    ref,
    className: "button",
    disabled,
    title: label,
    "data-size": size,
    "data-use": use,
    "data-icon": iconOnly ? "only" : null,
    "data-loading": loading,
    "data-popover-id": popoverId,
    "data-selected": isSelected,
    "data-remove-padding": removePadding,
    onClick: handleClick,
  };

  if (url) {
    return (
      <Link to={url} {...buttonProps}>
        {!iconOnly && iconLead && <Icon icon={iconLead} position='element-lead' />}
        {iconOnly && <Icon icon={iconOnly} position='icon-only' />}
        {!iconOnly && <span className='label'>{label}</span>}
        {!iconOnly && iconTrail && <Icon icon={iconTrail} position='element-trail' />}
        {loading && (
          <span className='loading'>
            <Icon icon='Loading' />
          </span>
        )}
      </Link>
    );
  }

  return (
    <button {...buttonProps}>
      {!iconOnly && iconLead && <Icon icon={iconLead} position='element-lead' />}
      {iconOnly && <Icon icon={iconOnly} position='icon-only' />}
      {!iconOnly && <span className='label'>{label}</span>}
      {!iconOnly && iconTrail && <Icon icon={iconTrail} position='element-trail' />}
      {loading && (
        <span className='loading'>
          <Icon icon='Loading' />
        </span>
      )}
    </button>
  );
}

export default React.forwardRef(Button);
