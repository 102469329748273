import { FetchUsersName, DateTime } from "Components/";
import React from "react";

function CellSavedReply({ contents, ...otherProps }) {
  const authorName = FetchUsersName(contents.author);
  return (
    <>
      <header>
        <div className='title-author'>
          <span className='title'>{contents.title}</span>
          by
          <span className='author'>{authorName}</span>
        </div>
        <span className='date'>
          <DateTime dateTime={contents.created_at} />
        </span>
      </header>
      <div className='body'>{contents.content}</div>
    </>
  );
}

export default CellSavedReply;
