import { useState, useEffect, useReducer } from "react";
import TicketReplyWindow from "./parts/TicketReplyWindow";
import TicketMessage from "./parts/TicketMessage";
import TicketHeader from "./parts/TicketHeader";
import TicketTagAdded from "./parts/TicketTagAdded";
import { useGetTicketDataRows } from "Hooks/useGetTicketDataRows";
import { useParams } from "react-router-dom";
import { useGetSlugRow } from "Hooks/useGetSlugRow";
import { MessageTagReducer, actionTypes } from "./parts/MessageTagReducer";

import AddTagWindow from "Compositions/AddTagWindow/AddTagWindow";
function Tickets() {
  const [state, dispatch] = useReducer(MessageTagReducer, { items: [] });
  const [replyType, setReplyType] = useState("reply");

  const slug = useParams().slug;
  const {
    data: slugTicket,
    isLoading: slugTicketIsLoading,
    error: slugTicketError,
  } = useGetSlugRow("tickets", slug);

  const ticketId = slugTicket?.id;

  const {
    data: messages,
    isLoading: messagesLoading,
    error: messagesError,
  } = useGetTicketDataRows({
    table: "ticket_messages",
    id: ticketId,
    sortColumn: "created_at",
    sortAscending: true,
  });

  const {
    data: ticketTags,
    isLoading: tagsLoading,
    error: tagsError,
  } = useGetTicketDataRows({
    table: "ticket_tags",
    id: ticketId,
  });

  useEffect(() => {
    if (!messagesLoading && !tagsLoading) {
      dispatch({
        type: actionTypes.SET_MESSAGES,
        payload: messages.map((message) => ({ ...message, timelineItemType: "message" })),
      });
      dispatch({
        type: actionTypes.SET_TAGS,
        payload: ticketTags.map((tag) => ({ ...tag, timelineItemType: "tag" })),
      });
    }
  }, [messages, ticketTags, messagesLoading, tagsLoading]);

  if (messagesLoading || tagsLoading || slugTicketIsLoading) {
    return <p>Loading</p>;
  }

  if (messagesError || tagsError || slugTicketError) {
    return (
      <>
        {messagesError.message ? <div>Error: {messagesError.message}</div> : null}
        {tagsError.message ? <div>Error: {tagsError.message}</div> : null}
      </>
    );
  }

  if (state.items.length === 0) {
    return <div>No messages or tags</div>;
  }

  return (
    <div className='ticket'>
      <div className='ticket-page'>
        <div className='ticket-container'>
          <TicketHeader slug={slug} />
          {state.items.map((item, index) => {
            if (item.timelineItemType === "message") {
              return <TicketMessage key={item.id} message={item} />;
            } else if (item.timelineItemType === "tag") {
              return <TicketTagAdded key={index} tags={item.tags} />;
            }
            return null;
          })}
          <TicketReplyWindow replyType={replyType} setReplyType={setReplyType} />
        </div>
      </div>
      <AddTagWindow ticketId={ticketId} />
    </div>
  );
}

export default Tickets;
