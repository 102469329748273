import PageHeader from "Compositions/PageHeader/PageHeader";
import { useGetRows } from "Hooks/";
import TagRow from "./TagRow";
import { createPortal } from "react-dom";
import { Outlet } from "react-router-dom";

function Tags() {
  const {
    data: tagsData,
    isLoading: tagsLoading,
    error: tagsError,
  } = useGetRows({ table: "tags" });
  const {
    data: tagCategoriesData,
    isLoading: tagCategoriesLoading,
    error: tagCategoriesError,
  } = useGetRows({ table: "tag_categories" });

  if (tagsLoading || tagCategoriesLoading) {
    return <p>Loading...</p>;
  }

  if (tagsError || tagCategoriesError) {
    return (
      <p>
        Error: {tagsError?.message} {tagCategoriesError?.message}
      </p>
    );
  }

  return (
    <div className='settings-page'>
      <PageHeader title='Tags' icon='Tags' guideUrl='https://fixatehq.com' subtitle='subtitle' />
      <table>
        <thead>
          <TagRow type='header' />
        </thead>

        {tagCategoriesData.map((category) => (
          <TagRow key={category.id} type='group' row={category}>
            {tagsData
              .filter((tag) => tag.tag_category === category.id)
              .map((row) => (
                <TagRow key={row.id} row={row} />
              ))}
          </TagRow>
        ))}
      </table>
      {createPortal(<Outlet />, document.body)}
    </div>
  );
}

export default Tags;

