import { FetchProfileColor } from "Components/";
import { useGetRow } from "Hooks/useGetRow";
import React from "react";

function Avatar({ id, url, name, initials, style = "circle", size = "medium", profileColor }) {
  const { data, isLoading, error } = useGetRow("user_profiles", id);
  const color = FetchProfileColor(profileColor);
  const idColor = FetchProfileColor(data?.profile_color);
  if (!id) {
    return (
      <div
        className='avatar'
        data-size={size}
        data-style={style}
        data-profile-color={!url ? color : null}
      >
        {!url ? <span className='initials'>{initials}</span> : null}
        {url ? <img src={url} alt={name} /> : null}
      </div>
    );
  }
  if (isLoading || error) {
    return null;
  }
  if (id) {
    return (
      <div
        className='avatar'
        data-size={size}
        data-style={style}
        data-profile-color={!data?.avatar ? idColor : null}
      >
        {!data?.avatar && <span className='initials'>{data?.initials}</span>}
        {data?.avatar && <img src={data?.avatar} alt={data?.name} />}
      </div>
    );
  }
}

export default Avatar;
