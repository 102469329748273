import { Avatar, FormBlock, FormItem, FormRow } from "Components/index";
import { SettingsFooter } from "Compositions/index";
import { useGetSlugRow, useForm } from "Hooks/";
import PanelHeader from "Layouts/Panel/PanelHeader";
import { SettingsSection } from "Layouts/index";
import React from "react";
import { useParams } from "react-router-dom";

function SavedReplyItem() {
  const slug = useParams().slug;
  const {
    data: slugUser,
    isLoading: slugUserIsLoading,
    error: slugUserError,
  } = useGetSlugRow("user_profiles", slug);

  const id = slugUser?.id;
  const table = "user_profiles";

  const {
    formState,
    unsavedChanges,
    statusText,
    isLoading,
    error,
    discardChanges,
    handleFormTextChange,
    saveChanges,
  } = useForm(table, id, {});

  const pageDefinitions = {
    title: "Your account",
    icon: "YourAccount",
    subtitle: "Tell us about yourself",
  };

  if (isLoading || error || slugUserIsLoading || slugUserError || !formState.first_name) {
    return <p>loading...</p>;
  }
  return (
    <>
      <PanelHeader
        title={
          <>
            <Avatar size='small' initials='pj' id={formState.id} />{" "}
            <h1>
              {formState.first_name} {formState.last_name}
            </h1>
          </>
        }
      />

      <SettingsSection>
        <FormBlock>
          <FormRow>
            <FormItem
              label='First name'
              name='first_name'
              itemType='input'
              inputType='text'
              value={formState.first_name}
              onChange={handleFormTextChange}
            />
            <FormItem
              label='Last name'
              name='last_name'
              itemType='input'
              inputType='text'
              value={formState.last_name}
              onChange={handleFormTextChange}
            />
          </FormRow>
          <FormRow>
            <FormItem
              label='role'
              name='role'
              itemType='input'
              inputType='text'
              value={formState.role}
              onChange={handleFormTextChange}
            />
          </FormRow>
        </FormBlock>
      </SettingsSection>
      <SettingsFooter
        unsavedChanges={unsavedChanges}
        discardChanges={discardChanges}
        saveChanges={saveChanges}
        pageName={pageDefinitions.title}
        unsavedChangesText={statusText}
      />
    </>
  );
}

export default SavedReplyItem;
