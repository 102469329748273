import React from "react";
import { Icon } from "Components/";

function Logo() {
  return (
    <span className='hark-logo'>
      <Icon icon='Fixate' />
      <h1>Hark</h1>
    </span>
  );
}

export default Logo;
