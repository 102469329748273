import React, { useEffect, useState } from 'react'
import { supabase } from "Config/supabase";
import { Link } from "react-router-dom";

function Logout() {
  const [status, setStatus] = useState('You are logging out...')

  useEffect(() => {
    async function signOut() {
      let { error } = await supabase.auth.signOut();

      if (error) {
        console.error("Error signing out:", error.message);
        setStatus("Error during logout.");
      } else {
        setStatus("You are logged out.");
      }
    }

    signOut();
  }, []);

  return (
    <div>
      {status} <Link to='/login'> Login.</Link>
    </div>
  );
}

export default Logout
