import { useState, useEffect } from "react";
import { supabase } from "Config/supabase";

import { useQuery } from "@tanstack/react-query";
export function useCurrentUser() {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const tokenString = localStorage.getItem("sb-repzijxdaksdftljogfb-auth-token");
    const token = JSON.parse(tokenString);
    const userIdFromToken = token?.user?.id || null;
    setUserId(userIdFromToken);
  }, []);

  const fetchUser = async () => {
    try {
      if (!userId) return null; // Return immediately if userId is not set

      const { data, error } = await supabase
        .from("user_profiles")
        .select("*")
        .eq("id", userId)
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error("Error fetching user:", error);
      throw error;
    }
  };

  const { data, isLoading, error } = useQuery(["currentUser", "user_profiles", userId], fetchUser, {
    enabled: !!userId, // Only enable the query if userId is truthy
    staleTime: Infinity,
  });

  if (isLoading || error) {
    return { isLoading, error };
  }

  return {
    first_name: data.first_name,
    id: data.id,
    last_name: data.last_name,
    avatar_url: data.avatar_url,
    initials: data.initials,
    organization: data.organization,
  };
}
