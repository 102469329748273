import { Icon, TableCell } from "Components/index";

function TagRow({ type, row, children }) {
  switch (type) {
    case "header":
      return (
        <tr>
          <TableCell
            type='header'
            cellType='shortcut-key'
            contents={<Icon icon='ShortcutKey' size='small' />}
          />
          <TableCell type='header' contents='Tag' />
          <TableCell type='header' contents='Automation' />
          <TableCell type='header' cellType='ellipsis' contents='' />
        </tr>
      );
    case "group":
      return (
        <tbody>
          <tr className='group'>
            <TableCell type='header' contents={row.shortcut_key} />
            <TableCell
              type='header'
              contents={
                <>
                  <span className='category-dot' data-tag-type={row.slug}></span>
                  {row.name}
                </>
              }
            />
            <TableCell colSpan='2' type='header' cellType='group-add-button' contents='Add tag' />
          </tr>
          {children}
        </tbody>
      );
    default:
      return (
        <tr>
          <TableCell
            link={`/settings/tags/${row.slug}`}
            type='shortcut-key'
            subKey={true}
            contents={row.shortcut_key}
          />
          <TableCell link={`/settings/tags/${row.slug}`} type='tag' contents={row.id} />
          <TableCell
            link={`/settings/tags/${row.slug}`}
            type='automation'
            contents={row.automations}
          />

          <TableCell type='ellipsis' contents='hello' />
        </tr>
      );
  }
}

export default TagRow;
