import PageHeader from "Compositions/PageHeader/PageHeader";
import { useGetRows } from "Hooks/";
import SavedReplyRow from "./SavedReplyRow";
import { createPortal } from "react-dom";
import { Outlet } from "react-router-dom";

function SavedReplies() {
  const { data, isLoading, error } = useGetRows({
    table: "saved_replies",
    sortColumn: "updated_at",
    sortAscending: false,
  });

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div className='settings-page'>
      <PageHeader
        title='Saved Replies'
        guideUrl='https://fixatehq.com'
        icon='Replies'
        subtitle='subtitle'
      />
      <table>
        <thead>
          <SavedReplyRow type='header' />
        </thead>
        <tbody>
          {data.map((row) => (
            <SavedReplyRow type='row' key={row.id} row={row} />
          ))}
        </tbody>
      </table>
      {createPortal(<Outlet />, document.body)}
    </div>
  );
}

export default SavedReplies;
